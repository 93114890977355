import React from "react";

const ExpiredBadge = () => (
  <small
    className="badge-pill badge-danger py-1 px-2 mx-1 font-weight-bold text-uppercase"
    style={{ background: "#e54c30", display: "inline-block" }}
  >
    Vencido
  </small>
);

export default ExpiredBadge;
