import React from 'react'
import { Field, Form, Formik } from 'formik';
import ButtonPrimary from '../Buttons/ButtonPrimary'

const DismissDocumentModal = ({ onCancel, onConfirm }) => {

    const handleSubmit = (values) => {
        onConfirm(values.justificativa);
    };

    return (
        <div className='mx-auto'>
            <Formik initialValues={{justificativa: null}} onSubmit={handleSubmit}>
                {({values, handleChange, handleBlur}) => (
                    <Form className='form-group'>
                        <div className="d-flex flex-column justify-content-center align-items-center mx-auto mt-4">
                            <p style={{ fontWeight: 'bold'}}>Deseja dispensar este documento para este funcionário?</p>
                            <p>Atenção: o funcionário não poderá realizar atividades que exijam este documento, sob pena de responsabilidade solidária entre tomador e terceiro!</p>
                        </div>
                        <Field
                            type="text"
                            name="justificativa"
                            component="textarea"
                            className="form-control my-3"
                            placeholder="Justificativa"
                            rows="3"
                            value={values.motivo}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <div className='d-flex justify-content-center gap-1 w-full mt-2'>
                            <ButtonPrimary className="btn-cancel swal2-styled" onClick={onCancel}>
                                Cancelar
                            </ButtonPrimary>
                            <ButtonPrimary className="btn-confirm swal2-styled" type="submit">
                                Confirmar
                            </ButtonPrimary>
                        </div>
                     </Form>
                )}
            </Formik>
        </div>
    )
}

export default DismissDocumentModal
