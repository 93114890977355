import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

const BlockedBadge = () => (
  <OverlayTrigger
    placement="top"
    overlay={
      <Popover className="p-1" id="blocked-tooltip">
        Caso necessite fazer upload de algum documento, solicitar a liberação do
        contrato
      </Popover>
    }
  >
    <small
      className="badge-pill badge-danger py-1 px-2 mx-1 font-weight-bold text-uppercase"
      style={{ background: "#f15923", display: "inline-block" }}
    >
      Bloqueado
    </small>
  </OverlayTrigger>
);

export default BlockedBadge;
