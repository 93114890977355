/* Geral / Bibliotecas */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import ReactTable from "react-table"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import "react-table/react-table.css"

/* Métodos / Ações */
import { fetchList, fetch, clearData, resendEmailCadastro } from '../../actions/contratos'
import { setModalOpen, setModalId } from '../../actions/modal'
import { clearFuncionarioId } from '../../actions/scroll'

/* Cores */
import { COLOR_INFO } from '../../Colors';

/* Componentes */
import Error from '../Error'
import RightPanel from '../Sidepanel/RightPanel'
import ContratoForm from './ContratoForm'
import ContratoDocumentos from './ContratoDocumentos'
import ContratoFuncionarios from './ContratoFuncionarios'
import ResendEmailForm from '../Inicio/ResendEmailForm'

/* Botões */
import ButtonPrimary from '../Buttons/ButtonPrimary'

import {EditarContratoButton} from './Buttons/EditarContratoButton'
import {AbreGestaoContratoButton} from './Buttons/AbreGestaoContratoButton'
import DoubleClickableCell from '../Table/Cells/DoubleClickableCell'
import AlertSemPlano from './AlertSemPlano'
import { STATUS } from '../../types/StatusContrato'
import BlockedBadge from './BlockedBadge'
import ExpiredBadge from './ExpiredBadge'
import { TIPOS } from '../../types/TipoContrato'
import { isExpired } from '../../helpers/contrato'

const MySwal = withReactContent(Swal)

class Contratos extends Component {
    constructor() {
        super()

        this._isMounted = false
        this.state = {
            rows: [],
            pages: null,
            loading: true,
            pageSize: null,
            page: null,
            sorted: null,
            filtered: null,
            statusFilter: null,
            contratoAguardandoCadastro: [],
        }

        this.fetchData = this.fetchData.bind(this)
        this.handleOpen = this.handleOpen.bind(this)
        this.requestData = this.requestData.bind(this)
        this.handleStatusFilter = this.handleStatusFilter.bind(this)
        this.handleCreateContract = this.handleCreateContract.bind(this)
    }

    requestData() {
        let reqData = {
            pageSize: this.state.pageSize,
            page: this.state.page,
            sort: this.state.sorted,
            filter: (this.state.filtered || []).filter(v => v.id !== "status")
        }

        if (this.state.statusFilter !== null) {
            reqData.filter.push({id:"status", value: this.state.statusFilter})
        }

        return new Promise((resolve, reject) => fetchList(
            reqData,
            resolve,
            reject,
            this.props.dispatch,
        )).then(res => {
            if (this._isMounted) {
                this.setState({
                    rows: res.rows,
                    pages: res.pages,
                    loading: false
                })
            }
        }).catch(err => {
            if (this._isMounted) {
                this.setState({
                    rows: [],
                    pages: 0,
                    loading: false
                })
            }
        })
    }

    fetchData(state, instance) {
        this.setState({
            loading: true,
            pageSize: state.pageSize,
            page: state.page,
            sorted: state.sorted,
            filtered: state.filtered,
        },()=>{
            this.requestData()
        })
    }

    handleStatusFilter(st = null){
        this.setState(
            {
                statusFilter: st,
                loading: true,
            },
            () => this.requestData()
        )
    }

    requestContratoData = async (id) => {
        if (this.state.contratoAguardandoCadastro.length === 0 || this.state.contratoAguardandoCadastro.some(item => item.idContrato !== id)) {
            const res = await this.props.dispatch(fetch(id))

            this.setState({ contratoAguardandoCadastro: [...this.state.contratoAguardandoCadastro, res] })
        }
    }

    handleOpenResend = (id, email) => {
        return MySwal.fire({
                title: '',
                type: 'info',
                html: <ResendEmailForm
                        onSubmit={(val) => {
                            if (val.email) {
                                this.props.dispatch(resendEmailCadastro(id, val.email))
                            }

                            Swal.clickConfirm()
                        }}
                        defaultValue={{ email }}
                        />,
                showCancelButton: false,
                showConfirmButton: false,
            })
    }

    handleSaveLinkToClipboard = (hashCadastro) => {
        navigator.clipboard.writeText(`${window.location.origin}/quero-me-cadastrar/${hashCadastro}`)

        MySwal.fire({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            type: 'success',
            title: 'Link copiado.'
        })
    }

    componentDidMount() {
        this._isMounted = true

        this.props.dispatch(clearFuncionarioId())
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    handleOpen(props){
        this.props.dispatch(setModalOpen('edit'))
        this.props.dispatch(setModalId(props.idContrato))
    }

    handleCreateContract() {
        if (!this.props?.usuarioLogado?.planoCriarContratos) {
          return MySwal.fire({
            title: "",
            type: "question",
            html: <AlertSemPlano />,
            showCancelButton: false,
          });
        }

        this.props.dispatch(clearData())
        this.props.dispatch(setModalOpen('new'))
    }

    render() {
        if (this.props.error && this.props.modal === '') {
            return <Error erro={this.props.error}></Error>
        }

        return(
            <div className="pb-3">
                <div className="row justify-content-center mx-0">

                    <div className="col-12 px-0">
                        <div className="card tertium-card">
                            <div className="card-header py-3 px-2 px-lg-5">
                                Contratos
                                <ButtonPrimary title="Criar novo contrato" onClick={this.handleCreateContract}
                                style={{
                                    position: 'absolute',
                                    right: '0',
                                    top: '0',
                                    height: '100%',
                                    borderRadius: '0',
                                    boxShadow: 'none',
                                    borderTopRightRadius: 'calc(.25rem - 1px)',
                                    backgroundColor: '#f15923',
                                    borderBottom: '1px solid #cd4c1e',
                                    marginBottom: '-1px'
                                }}><span className="d-none d-md-inline-block px-5 mx-3">Novo contrato</span>
                                    <img src={"/images/plus.png"} alt="Criar novo contrato" title="Criar novo contrato" className="img-fluid ml-0 plus-img" style={{
                                        maxWidth: '25px',
                                        transform: 'translateY(-3px)',
                                        position: 'absolute',
                                        right: '20px'
                                    }}/>
                                </ButtonPrimary>
                            </div>
                            <div className="card-body px-2 px-lg-5">
                                <div className="row mx-0 mb-4 pt-4 row-table-filters">
                                    <button type="button" className={"col-auto pb-3 px-0 mr-5"+(this.state.statusFilter === null ? ' active' : '')} onClick={()=>{this.handleStatusFilter(null)}}>
                                        Todas
                                    </button>
                                    <button type="button" className={"col-auto pb-3 px-0 mr-5"+(this.state.statusFilter === 1 ? ' active' : '')} onClick={()=>{this.handleStatusFilter(1)}}>
                                        Ativos
                                    </button>
                                    <button type="button" className={"col-auto pb-3 px-0 mr-5"+(this.state.statusFilter === 0 ? ' active' : '')} onClick={()=>{this.handleStatusFilter(0)}}>
                                        Suspensos
                                    </button>
                                </div>
                                <ReactTable
                                    defaultSorted={[{ id:"empresaStatus", desc: false },{ id:"nome", desc: false }]}
                                    columns={[
                                        {
                                            Header: "",
                                            accessor: "letra",
                                            filterable:false,
                                            sortable:false,
                                            className: 'option-column-5 letra-inicial',
                                            headerClassName: 'option-column-5',
                                            Cell: props => <div style={{
                                                borderTop: props.original.letra !== '' ? '2px solid white' : 'none',
                                                borderTopRightRadius: props.original.letra !== '' ? 'calc(.25rem - 1px)' : '0',
                                                borderTopLeftRadius: props.original.letra !== '' ? 'calc(.25rem - 1px)' : '0',
                                                borderBottomRightRadius: (props.original.letra_len > 1 && props.original.letra !== '')  ? '0' : 'calc(.25rem - 1px)',
                                                borderBottomLeftRadius: (props.original.letra_len > 1 && props.original.letra !== '') ? '0' : 'calc(.25rem - 1px)',
                                            }} onDoubleClick={()=>this.handleOpen(props.original)}>
                                                {props.original.letra !== '' ? props.original.letra : ' '}
                                            </div>,
                                        },
                                        {
                                            Header: "",
                                            accessor: "tipo",
                                            className: "font-weight-600 text-uppercase",
                                            filterable: false,
                                            sortable: false,
                                            Cell: props => {
                                                if(props.value === 'terceiro'){
                                                    return <span onDoubleClick={()=>this.handleOpen(props.original)} style={{ color: '#35bae9', fontWeight: 'bold' }} className="small"><FontAwesomeIcon icon="id-card-alt" className="mr-2" />Sou terceiro</span>
                                                } else {
                                                    return <span onDoubleClick={()=>this.handleOpen(props.original)} style={{ color: '#2bb442', fontWeight: 'bold' }} className="small"><FontAwesomeIcon icon="building" className="mr-2" />Sou tomador</span>
                                                }
                                            }
                                        },
                                        {
                                            Header: "Título",
                                            accessor: "nome",
                                            headerClassName: 'titulo-placeholder',
                                            className: "font-weight-600 text-uppercase",
                                            Cell: props => {
                                                const isExpired =
                                                    props.original.dataFim?.date &&
                                                    moment(props.original.dataFim.date).isBefore(
                                                        moment()
                                                );

                                                return <div onDoubleClick={()=>this.handleOpen(props.original)}>
                                                    {props.original.status === STATUS.AGUARDANDO_APROVACAO &&
                                                        <span className="small font-weight-bold" style={{color:COLOR_INFO}}>(Aguardando Aprovação)<br/></span>
                                                    }
                                                    {props.value}
                                                    {props.original.status === STATUS.INATIVO && (
                                                        <BlockedBadge />
                                                    )}
                                                    {props.original.status !== STATUS.INATIVO && isExpired && (
                                                        <ExpiredBadge />
                                                    )}
                                                </div>
                                            }
                                        },
                                        {
                                            Header: "Filial",
                                            accessor: "filial",
                                            sortable:false,
                                            filterable:false,
                                            Cell: (props) => <DoubleClickableCell view="contratos" cellInfo={props} handleOpen={this.handleOpen} />,
                                        },
                                        {
                                            Header: "Empresa",
                                            accessor: "empresa",
                                            sortable:false,
                                            filterable:false,
                                            Cell: props => {
                                                return <div onDoubleClick={()=>this.handleOpen(props.original)}>
                                                    {props.original.empresaStatus === -1 &&
                                                        <span className="small font-weight-bold" style={{color:COLOR_INFO}}>(Aguardando Cadastro)<br/></span>
                                                    }
                                                    {props.value}
                                                </div>
                                            },
                                        },
                                        {
                                            Header: "Data Inicial",
                                            accessor: "dataInicio",
                                            filterable:false,
                                            maxWidth: 200,
                                            Cell: props => {
                                                if(props.value.date){
                                                    return <div onDoubleClick={()=>this.handleOpen(props.original)}>{moment(props.value.date).format('DD/MM/YYYY')}</div>
                                                } else {
                                                    return <div onDoubleClick={()=>this.handleOpen(props.original)}>--/--/---</div>
                                                }
                                            }
                                        },
                                        {
                                            Header: "Data Final",
                                            accessor: "dataFim",
                                            filterable:false,
                                            maxWidth: 200,
                                            Cell: props => {
                                                if(props.value.date){
                                                    return <div onDoubleClick={()=>this.handleOpen(props.original)}>{moment(props.value.date).format('DD/MM/YYYY')}</div>
                                                } else {
                                                    return <div onDoubleClick={()=>this.handleOpen(props.original)}>--/--/---</div>
                                                }
                                            }
                                        },
                                        {
                                            Header: '',
                                            filterable:false,
                                            sortable:false,
                                            className: 'option-column-5',
                                            headerClassName: 'option-column-5',
                                            Cell: props => {
                                                if (
                                                    props.original.tipo !== TIPOS.TERCEIRO &&
                                                    props.original.status === STATUS.AGUARDANDO_APROVACAO
                                                ) {
                                                    return null;
                                                }

                                                if (props.original.tipo === TIPOS.TERCEIRO && (
                                                    props.original.status === STATUS.INATIVO ||
                                                    isExpired(props.original)
                                                )) {
                                                    return null;
                                                }

                                                return <button
                                                    className="a"
                                                    onClick={() => this.handleOpen(props.original)}
                                                    title="Ver">
                                                    <FontAwesomeIcon icon="eye" />
                                                </button>
                                            }
                                        },
                                        {
                                            Header: '',
                                            filterable:false,
                                            sortable:false,
                                            className: 'option-column-5',
                                            headerClassName: 'option-column-5',
                                            Cell: props => <EditarContratoButton
                                                contrato={props.original}
                                                dispatch={this.props.dispatch}
                                            />,
                                        },
                                        {
                                            Header: '',
                                            filterable:false,
                                            sortable:false,
                                            className: 'option-column-5',
                                            headerClassName: 'option-column-5',
                                            Cell: props => <AbreGestaoContratoButton
                                                appData={this.props.appData}
                                                contrato={props.original}
                                                dispatch={this.props.dispatch}
                                                history={this.props.history}
                                            />
                                        },
                                        {
                                            Header: '',
                                            filterable:false,
                                            sortable:true,
                                            className: 'option-column-5',
                                            headerClassName: 'option-column-5',
                                            accessor: "empresaStatus",
                                            Cell: props => props.original.empresaStatus === -1
                                                && <button
                                                    className="a"
                                                    onClick={async () => {
                                                        await this.requestContratoData(props.original.idContrato)

                                                        const { chaveCadastro } = this.state.contratoAguardandoCadastro.find(({ idContrato }) => idContrato === props.original.idContrato)

                                                        this.handleSaveLinkToClipboard(chaveCadastro)
                                                    }}
                                                    title="Copiar Link Cadastro">
                                                    <FontAwesomeIcon icon="copy" />
                                                </button>
                                        },
                                        {
                                            Header: '',
                                            filterable:false,
                                            sortable:true,
                                            className: 'option-column-5',
                                            headerClassName: 'option-column-5',
                                            accessor: "empresaStatus",
                                            Cell: props => props.original.empresaStatus === -1 && <button className="a" onClick={async () => {
                                                await this.requestContratoData(props.original.idContrato)

                                                const contrato = this.state.contratoAguardandoCadastro.find(({idContrato}) => idContrato === props.original.idContrato)

                                                this.handleOpenResend(contrato.idFilial, contrato.email)
                                            }} title="Reenviar"><FontAwesomeIcon icon="redo" /></button>
                                        },
                                    ]}
                                    manual
                                    multiSort={false}
                                    data={this.state.rows}
                                    pages={this.state.pages}
                                    loading={this.state.loading}
                                    onFetchData={(state, instance)=>this.fetchData(state, instance)}
                                    filterable
                                    previousText={<FontAwesomeIcon icon="chevron-left" />}
                                    nextText={<FontAwesomeIcon icon="chevron-right" />}
                                    resizable={false}
                                    loadingText="Carregando..."
                                    noDataText="Nenhum contrato encontrado"
                                    pageText="Página"
                                    ofText="de"
                                    rowsText="linhas"
                                    pageJumpText="ir para a página"
                                    rowsSelectorText="linhas por página"
                                    pageSizeOptions={[5, 10, 20, 50]}
                                    defaultPageSize={10}
                                    minRows={0}
                                    className="-highlight"
                                    />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Formulário de Criação */}
                <RightPanel isPaneOpen={this.props.modal === 'new'} title="Novo contrato" path="new" onClose={()=>{
                    this.props.dispatch(setModalOpen(''))
                }}>
                    <ContratoForm onAfterSave={this.requestData}></ContratoForm>
                </RightPanel>

                <RightPanel isPaneOpen={(this.props.modal === 'edit' && this.props.id !== null)} title="Edição de Contrato" path="edit" onClose={()=>{
                    this.props.dispatch(setModalOpen(''))
                }}>
                    <ContratoForm onAfterSave={this.requestData} id={this.props.id}></ContratoForm>
                </RightPanel>

                {/* Edição de Documentos do Contrato */}
                <RightPanel isPaneOpen={this.props.modal === 'documentos'} title="Documentos do Contrato" path="documentos" onClose={()=>{
                    this.props.dispatch(setModalOpen(''))
                }}>
                    <ContratoDocumentos onAfterSave={this.requestData}></ContratoDocumentos>
                </RightPanel>

                {/* Edição de Funcionários do Contrato */}
                <RightPanel isPaneOpen={this.props.modal === 'funcionarios'} title="Funcionários do Contrato" path="funcionarios" onClose={()=>{
                    this.props.dispatch(setModalOpen(''))
                }}>
                    <ContratoFuncionarios onAfterSave={this.requestData}></ContratoFuncionarios>
                </RightPanel>
            </div>
        )
    }
}
Contratos.propTypes = {
    modal: PropTypes.string.isRequired,
    id: PropTypes.any,
}

const mapStateToProps = (state) => ({
    appData: state.dados.data,
    usuarioLogado: state.usuarioLogado.dados,
    modal: state.modal.modalOpen,
    id: state.modal.id,
    error: state.errors.message,
})

export default connect(mapStateToProps)(withRouter(Contratos));
