import React, { useCallback, useEffect, useMemo, useState } from "react";

/* UTILS */
import moment from "moment";
import { parseISO, isFuture } from "date-fns";
import { formatDate } from "../../helpers/formatDate";
import { isAuditoria } from "../../helpers/isAuditoria";

/* COLORS */
import { COLOR_WARNING, COLOR_GRAY2 } from "../../Colors";

/* COMPONENTS */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import ButtonPrimary from "../Buttons/ButtonPrimary";
import SituacaoCell from "./Cells/SituacaoCell";
import TrocaContratoButton from "./Buttons/TrocaContratoButton";
import AprovarDocumentosButton from "./Buttons/AprovarDocumentosButton";
import Table from "./Table";
import {SITUACAO} from "../../types/SituacaoDocumento";
import { clearFuncionarioId } from "../../actions/scroll";
import { clearFilialFuncionarioId } from "../../actions/filialFuncionario";
import UploadColumn from "./CellDef/UploadColumn";

/* CONSTANTS */
export const DEMITIDO = 2;
const ONLY_VIEW_COLUMNS = ["nome", "view"];
const EXPIRED_COLUMNS = [
  "nome",
  "view",
  "status",
  "comentarios",
  "dataFim.date",
  "dataInicio.date",
];

const DocumentosFuncionarioTable = ({
  dispatch,
  contratoFilter,
  filialFilter,
  funcionario,
  handleEmployeeDismissal,
  handleAprovaReprovaTrocaContrato,
  handleLoadContratos,
  handleOpen,
  handleOpenJustificativa,
  onDocumentClickUpload,
  onAprovarDocumentosClick,
  onlyView,
  scroll,
  show,
  setShow,
  expired,
  delayed = false,
}) => {
  const [hidden, setHidden] = useState(!!delayed);

  const scrollToEmployee = useCallback(ref => {
    if (ref && dispatch && funcionario.idFuncionario === scroll?.idFuncionario) {
      dispatch(clearFuncionarioId());
      dispatch(clearFilialFuncionarioId());
      ref.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }, [dispatch, funcionario, scroll])

  const documentos = useMemo(
    () => Array.isArray(funcionario.documentos)
      ? funcionario.documentos
      : Object.values(funcionario.documentos),
    [funcionario.documentos],
  );

  const funcionariosInvalidosLength = useMemo(
    () => documentos.filter((documento) => documento.status < 1 || documento.status === SITUACAO.AGUARDANDO_APROVACAO)?.length,
    [documentos],
  );

  const canApproveDocuments = useMemo(
    () => ! onlyView && contratoFilter?.tipo === "tomador" &&
      documentos.some((documento) => documento.status === SITUACAO.AGUARDANDO_APROVACAO),
    [contratoFilter, documentos, onlyView],
  );

  const canDismissDocument = useCallback(
    (documento) =>
      contratoFilter?.tipo === "terceiro" ||
      (contratoFilter?.tipo === "tomador" &&
        [
          SITUACAO.UPLOAD_PENDENTE,
          SITUACAO.EXPIRADO,
          SITUACAO.EXPIRANDO,
        ].includes(documento.status) &&
        !isAuditoria(funcionario, documento.status)),
    [contratoFilter, funcionario]
  );

  const afastamentoDataInicio = useMemo(() => parseISO(funcionario.afastamento?.dataInicio), [funcionario.afastamento]);
  const afastamentoDataFim = useMemo(() => parseISO(funcionario.afastamento?.dataFim), [funcionario.afastamento]);

  const columns = useMemo(() => [
    {
      Header: "Nome",
      accessor: "nome",
      headerClassName: "",
      filterable: false,
      className: "font-weight-600",
      Cell: (props) => {
        return (
          <div
            style={{
              color: props.original.dispensado ? COLOR_GRAY2 : "initial",
            }}
            onDoubleClick={() => handleOpen(props.original, funcionario)}
            title={props.original.dispensado ? "Documento dispensado" : null}
          >
            {!!props.original.dispensado && (
              <img
                src="/images/documento_dispensado.png"
                alt="Documento dispensado"
                style={{
                  height: "1.8em",
                  opacity: 0.75,
                  mixBlendMode: "multiply",
                  userSelect: "none",
                  float: "left",
                }}
              />
            )}
            {props.value}
          </div>
        );
      },
    },
    {
      Header: "Data Inicial",
      accessor: "dataInicio.date",
      filterable: false,
      maxWidth: 120,
      Cell: (props) => {
        if (props.value) {
          return (
            <div onDoubleClick={() => handleOpen(props.original, funcionario)}>
              {moment(props.value).format("DD/MM/YYYY")}
            </div>
          );
        } else {
          return (
            <div onDoubleClick={() => handleOpen(props.original, funcionario)}>
              --/--/---
            </div>
          );
        }
      },
    },
    {
      Header: "Data Final",
      accessor: "dataFim.date",
      filterable: false,
      maxWidth: 120,
      Cell: (props) => {
        if (props.value) {
          return (
            <div onDoubleClick={() => handleOpen(props.original, funcionario)}>
              {moment(props.value).format("DD/MM/YYYY")}
            </div>
          );
        } else {
          return (
            <div onDoubleClick={() => handleOpen(props.original, funcionario)}>
              --/--/---
            </div>
          );
        }
      },
    },
    {
      Header: "Situação",
      accessor: "status",
      filterable: false,
      maxWidth: 150,
      Cell: (props) => (
        <SituacaoCell
          funcionario={funcionario}
          handleOpen={handleOpen}
          handleOpenJustificativa={handleOpenJustificativa}
          props={props}
        />
      ),
    },
    {
      Header: "Comentários",
      accessor: "comentarios",
      filterable: false,
      maxWidth: 150,
      Cell: (props) => {
        return (
          <div>
            {props.value}
            <span className="d-inline-block d-md-none pl-2">Comentário(s)</span>
            <button
              className="a ml-4"
              onClick={() =>
                handleOpen(props.original, funcionario, undefined, true)
              }
              title="Ver"
            >
              <FontAwesomeIcon icon="envelope" />
            </button>
          </div>
        );
      },
    },
    {
      Header: "",
      accessor: "view",
      filterable: false,
      sortable: false,
      className: "option-column-5",
      headerClassName: "option-column-5",
      maxWidth: 10,
      Cell: (props) => {
        return (
          <button
            className="a"
            onClick={() => {
              window.history.pushState(
                {
                  idFuncionario: funcionario.idFuncionario,
                },
                ""
              );
              handleOpen(props.original, funcionario);
            }}
            title="Ver"
          >
            <FontAwesomeIcon icon="eye" />
          </button>
        );
      },
    },
    {
      Header: "",
      filterable: false,
      sortable: false,
      className: "option-column-5",
      headerClassName: "option-column-5",
      maxWidth: 10,
      Cell: (row) => {
        return canDismissDocument(row.original) &&
          (
            <button
              className="a"
              onClick={() =>
                handleEmployeeDismissal({
                  ...row,
                  funcionario,
                })
              }
              title="Dispensar"
            >
              <FontAwesomeIcon icon="file-excel" />
            </button>
          )
      }
    },
    UploadColumn({
      contrato: contratoFilter,
      hidden: row => isAuditoria(funcionario, row.original.status),
      onClick: (_, row) => onDocumentClickUpload(row, funcionario),
    }),
  ], [contratoFilter, handleOpen, funcionario, handleOpenJustificativa, canDismissDocument, handleEmployeeDismissal, onDocumentClickUpload]);

  const getOnlyViewColumns = useCallback(
    (column) => onlyView ? ONLY_VIEW_COLUMNS.includes(column.accessor) : true,
    [onlyView]
  )

  const getExpiredColumns = useCallback(
    (column) => expired ? EXPIRED_COLUMNS.includes(column.accessor) : true,
    [expired]
  )

  useEffect(() => {
    if (delayed && hidden) {
      const timeout = setTimeout(() => {
        setHidden(false);
      }, 500)

      return () => clearTimeout(timeout);
    }

    return () => null;
  }, [delayed, hidden])

  return hidden ? null : (
    <div className="row mb-4 mx-0">
      <div
        className={
          "col-12 px-0 d-flex align-items-center" +
          (!funcionario.cargo ? " mb-2" : "")
        }
      >
        <div className="flex-grow-1">
          <span
            className="font-weight-600 text-uppercase"
            ref={scrollToEmployee}
          >
            {funcionario.nome}
          </span>
          {funcionario.statusContratoFuncionario === DEMITIDO && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Popover
                  hidden={!funcionario.dataDemissao?.date}
                  className="p-1"
                  id="afastamento-tooltip"
                >
                  {funcionario.dataDemissao?.date &&
                    formatDate(funcionario.dataDemissao.date)}
                </Popover>
              }
            >
              <small
                className="badge-pill badge-danger py-1 px-2 mx-1 font-weight-bold text-uppercase"
                style={{ background: "#f15923" }}
              >
                Demitido
              </small>
            </OverlayTrigger>
          )}
          {funcionario.afastamento && isFuture(afastamentoDataFim) && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Popover className="p-1" id="afastamento-tooltip">
                  {formatDate(afastamentoDataInicio)} até{" "}
                  {formatDate(afastamentoDataFim)}
                </Popover>
              }
            >
              <small
                className="badge-pill badge-danger py-1 px-2 mx-1 font-weight-bold text-uppercase"
                style={{ background: "#f15923" }}
              >
                {funcionario.afastamento?.tipoAfastamentoNome}
              </small>
            </OverlayTrigger>
          )}
          {funcionario.email && `(${funcionario.email}) `}
          {funcionario.idade && (
            <span title={formatDate(funcionario.dataNascimento)}>
              {" "}
              ({funcionario.idade} anos)
            </span>
          )}
        </div>
        {canApproveDocuments && (
          <AprovarDocumentosButton
            funcionario={funcionario}
            documentos={documentos ?? []}
            onPress={onAprovarDocumentosClick}
          />
        )}
        {!onlyView && (
          <TrocaContratoButton
            contratoFilter={contratoFilter}
            filialFilter={filialFilter}
            funcionario={funcionario}
            handleAprovaReprovaTrocaContrato={handleAprovaReprovaTrocaContrato}
            handleLoadContratos={handleLoadContratos}
          />
        )}
      </div>
      {funcionario.cargo && (
        <div className="col-12 mb-2">
          <span>{funcionario.cargo}</span>
        </div>
      )}
      {funcionariosInvalidosLength === 0 &&
        !show.funcionarios[funcionario.idFuncionario] && (
          <div className="w-100">
            <div
              className="col-12 py-2"
              style={{
                backgroundColor: "#d5f0d9",
                color: "#2bb442",
                fontWeight: 500,
                borderRadius: "4px",
              }}
            >
              NÃO CONSTA NENHUMA IRREGULARIDADE
            </div>
            <div className="w-100 pt-3">
              <ButtonPrimary
                title="Ver mais documentos"
                onClick={() => {
                  show.funcionarios[funcionario.idFuncionario] = true;
                  setShow(show);
                }}
              >
                VER DOCUMENTOS
              </ButtonPrimary>
              {funcionariosInvalidosLength - 3 > 0 && (
                <span
                  className="ml-2 font-weight-500"
                  style={{
                    color: COLOR_WARNING,
                    fontSize: "14px",
                  }}
                >
                  <FontAwesomeIcon icon="exclamation-triangle" /> +{" "}
                  {funcionariosInvalidosLength - 3} irregulares
                </span>
              )}
            </div>
          </div>
        )}
      {(funcionariosInvalidosLength > 0 ||
        show.funcionarios[funcionario.idFuncionario]) && (
        <div className="col-12">
          <Table
            columns={
              columns
                .filter(getOnlyViewColumns)
                .filter(getExpiredColumns)
            }
            defaultPageSize={
              show.funcionarios[funcionario.idFuncionario]
                ? documentos?.length
                : 3
            }
            pageSize={
              show.funcionarios[funcionario.idFuncionario]
                ? documentos?.length
                : 3
            }
            multiSort={false}
            data={documentos}
            filterable={false}
            resizable={false}
            noDataText="Nenhum documento encontrado"
            minRows={0}
            showPagination={false}
            showPaginationBottom={false}
            showPageSizeOptions={false}
            className="-highlight mb-3"
          />
          {documentos?.length > 3 &&
            !show.funcionarios[funcionario.idFuncionario] && (
              <div className="w-100">
                <ButtonPrimary
                  title="Ver mais documentos"
                  onClick={() => {
                    show.funcionarios[funcionario.idFuncionario] = true;
                    setShow(show);
                  }}
                >
                  VER + DOCUMENTOS
                </ButtonPrimary>
                {funcionariosInvalidosLength - 3 > 0 && (
                  <span
                    className="ml-2 font-weight-500"
                    style={{
                      color: COLOR_WARNING,
                      fontSize: "14px",
                    }}
                  >
                    <FontAwesomeIcon icon="exclamation-triangle" /> +{" "}
                    {funcionariosInvalidosLength - 3} irregulares
                  </span>
                )}
              </div>
            )}
          {documentos?.length > 3 &&
            show.funcionarios[funcionario.idFuncionario] && (
              <ButtonPrimary
                title="Ver menos documentos"
                onClick={() => {
                  show.funcionarios[funcionario.idFuncionario] = false;
                  setShow(show);
                }}
              >
                VER - DOCUMENTOS
              </ButtonPrimary>
            )}
        </div>
      )}
    </div>
  );
};

export default DocumentosFuncionarioTable;
