/* Geral / Bibliotecas */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

/* Métodos / Ações */
import {
  clearFuncionario,
  fetchGestaoFuncionarios,
} from "../../actions/funcionarios";
import { debounce } from "../../helpers/debounce";
import {
  dismissDocument,
  fetchDocumentosFuncionariosList,
  fetchFuncionariosList,
  loadFiliaisDocumentosOptions,
} from "../../actions/documentos";
import { setModalOpen, setModalId } from "../../actions/modal";
import { setAppData } from "../../actions/dados";
import { clearFuncionarioId, setFuncionarioId } from "../../actions/scroll";
import { isIrregularDocument } from "../../helpers/isIrregularDocument";

/* Cores */
import { COLOR_DARK2, COLOR_PRIMARY, COLOR_VERY_LIGHT } from "../../Colors";

/* Componentes */
import Error from "../Error";
import RightPanel from "../Sidepanel/RightPanel";
import FuncionarioForm from "./FuncionarioForm";
import EmployeesList from "../EmployeesList/EmployeesList";
import DismissDocumentModal from "../GestaoDocumentos/DismissDocumentModal";

/* Botões */
import ButtonPrimary from "../Buttons/ButtonPrimary";
import LoadingImage from "../LoadingImage";
import Upload from "../Documento/Upload";
import { clearData, fetch, fetch as fetchEnvio } from "../../actions/envios";
import ContractsTable from "../ContractsTable/ContractsTable";

const MySwal = withReactContent(Swal);

const VisaoTomadorFuncionarios = ({
  appData,
  envio,
  envioLoading,
  dispatch,
  history,
  error,
  modal,
  id,
  scroll,
}) => {
  const branchInputRef = useRef(null);
  const employeeInputRef = useRef(null);
  const employeeRef = useRef(null);

  const [branches, setBranches] = useState([]);
  const [loadingBranches, setLoadingBranches] = useState(true);
  const [loadingEmployees, setLoadingEmployees] = useState(true);
  const [loadingFiredEmployees, setLoadingFiredEmployees] = useState(false);
  const [fetchData, setFetchData] = useState(true);
  const [fetchEmployees, setFetchEmployees] = useState(true);
  const [branchSearch, setBranchSearch] = useState("");
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [documents, setDocuments] = useState({});
  const [employeeSearch, setEmployeeSearch] = useState("");
  const [employees, setEmployees] = useState({});
  const [fetchFiredEmployees, setFetchFiredEmployees] = useState(false);
  const [firedEmployees, setFiredEmployees] = useState({});
  const [showingDocumentsFor, setShowingDocumentsFor] = useState([]);
  const [expandedDocumentsFor, setExpandedDocumentsFor] = useState([]);
  const [firedEmployeesAccordionOpen, setFiredEmployeesAccordionOpen] =
    useState(false);

  const [uploadEmployee, setUploadEmployee] = useState(null);

  const updateEmployees = useCallback(
    (id, data) =>
      setEmployees((state) => ({
        ...state,
        [id]: (data ?? [])
        .map((f) => ({ ...f, idFuncionario: f.funcionario, nome: f.nome.trim().toUpperCase() }))
          .sort((a, b) => (a.nome < b.nome ? 1 : -1)),
      })),
    []
  );

  const updateFiredEmployees = useCallback(
    (id, data) =>
      setFiredEmployees((state) => ({ ...state, [id]: data ?? [] })),
    []
  );

  const updateEmployeeDocuments = useCallback((id, data) => {
    setDocuments((state) => ({ ...state, [id]: data ?? {} }));
  }, []);

  const updateDocumentsAppData = useCallback(
    (id, data) => {
      setTimeout(() => {
        dispatch(
          setAppData({
            filtros: {
              ...appData.filtros,
              documentos: {
                ...(appData.filtros.documentos ?? {}),
                [id]: data ?? {},
              },
            },
          })
        );
      }, 700);
    },
    [appData.filtros, dispatch]
  );

  const loadBranches = useCallback(() => {
    setLoadingBranches(true);
    loadFiliaisDocumentosOptions(branchSearch)
      .then((branches) =>
        setBranches(
          branches.sort((a, b) =>
            [a.nome, a.idFilial] > [b.nome, b.idFilial] ? -1 : 1
          )
        )
      )
      .catch(() => setBranches([]))
      .finally(() => setLoadingBranches(false));
  }, [branchSearch]);

  const loadEmployees = useCallback(() => {
    setLoadingEmployees(true);
    const fetchEmployees = async () => {
      if (!selectedBranch) return;

      const funcionarios = appData.filtros?.funcionarios ?? {};

      const filter = [{ id: "idFilial", value: selectedBranch.idFilial }];

      fetchGestaoFuncionarios({ demitido: false, filter })(
        (data) => {
          updateEmployees(selectedBranch.idFilial, data.rows);
          dispatch(
            setAppData({
              filtros: {
                ...appData.filtros,
                funcionarios: {
                  ...funcionarios,
                  [selectedBranch?.idFilial]: data.rows,
                },
              },
            })
          );
        },
        (err) => {
          updateEmployees(selectedBranch.idFilial, []);
          dispatch(
            setAppData({
              filtros: {
                ...appData.filtros,
                funcionarios: {
                  ...funcionarios,
                  [selectedBranch?.idFilial]: [],
                },
              },
            })
          );
        }
      );
    };

    fetchEmployees().finally(() => setLoadingEmployees(false));
  }, [appData.filtros, dispatch, selectedBranch, updateEmployees]);

  const loadFiredEmployees = useCallback(() => {
    setLoadingFiredEmployees(true);
    const loadEmployees = async () => {
      if (!selectedBranch) return;

      const funcionariosDemitidos =
        appData.filtros?.funcionariosDemitidos ?? {};

      const filter = [{ id: "idFilial", value: selectedBranch.idFilial }];

      fetchGestaoFuncionarios(
        { demitido: true, filter },
        "funcionarios/fetchGestaoFuncionariosDemitidos"
      )(
        (data) => {
          updateFiredEmployees(selectedBranch.idFilial, data.rows);
          dispatch(
            setAppData({
              filtros: {
                ...appData.filtros,
                funcionariosDemitidos: {
                  ...funcionariosDemitidos,
                  [selectedBranch?.idFilial]: data.rows,
                },
              },
            })
          );
        },
        (err) => {
          updateFiredEmployees(selectedBranch.idFilial, []);
          dispatch(
            setAppData({
              filtros: {
                ...appData.filtros,
                funcionariosDemitidos: {
                  ...funcionariosDemitidos,
                  [selectedBranch?.idFilial]: [],
                },
              },
            })
          );
        }
      );
    };

    loadEmployees().finally(() => setLoadingFiredEmployees(false));
  }, [appData.filtros, dispatch, selectedBranch, updateFiredEmployees]);

  const handleAfterSave = useCallback(() => {
    loadEmployees();
    loadFiredEmployees();
  }, [loadEmployees, loadFiredEmployees]);

  const debounceBranchSearch = debounce((text) => setBranchSearch(text), 500);

  const handleBranchSearch = useCallback(
    (e) => debounceBranchSearch(e.target.value),
    [debounceBranchSearch]
  );

  const handleBranchChange = useCallback(
    (branch) => {
      setSelectedBranch(branch);
      setFetchEmployees(true);
      dispatch(setAppData({ filtros: { ...appData.filtros, filial: branch } }));
    },
    [dispatch, appData.filtros]
  );

  const handleBranchSearchFilter = useCallback(
    (branch) => {
      const search = new RegExp(branchSearch, "gi");
      return search ? branch?.nome.search(search) >= 0 : true;
    },
    [branchSearch]
  );

  const handleClearBranchFilter = useCallback(() => {
    branchInputRef.current.value = "";
    setBranchSearch("");
  }, []);

  const debounceEmployeeSearch = debounce(
    (text) => setEmployeeSearch(text),
    500
  );

  const handleEmployeeSearchFilter = useCallback(
    (employee) => {
      const search = new RegExp(employeeSearch, "gi");
      const hasOnlyNumberAndOrDot = employeeSearch
        .split("")
        .every((char) => /\d+|\.+/.test(char));
      const isDocument =
        hasOnlyNumberAndOrDot ||
        /\d{3}\.?\d{3}\.?\d{3}-?\d+/.test(employeeSearch);

      if (isDocument) {
        const value = employeeSearch
          .replace(/\D/g, "")
          .replace(/(\d{3})(\d)/, "$1.$2")
          .replace(/(\d{3})(\d)/, "$1.$2")
          .replace(/(\d{3})(\d+)$/, "$1-$2");

        return employee?.cpf.search(new RegExp(value, "g")) >= 0;
      }

      return search
        ? employee?.cargo.search(search) >= 0 ||
            employee?.nome.search(search) >= 0
        : true;
    },
    [employeeSearch]
  );

  const handleEmployeeSearch = useCallback(
    (e) => {
      debounceEmployeeSearch(e.target.value);
    },
    [debounceEmployeeSearch]
  );

  const branchEmployees = useMemo(
    () => (selectedBranch ? employees?.[selectedBranch.idFilial] ?? [] : []),
    [employees, selectedBranch]
  );

  const branchFiredEmployees = useMemo(
    () =>
      selectedBranch ? firedEmployees?.[selectedBranch.idFilial] ?? [] : [],
    [firedEmployees, selectedBranch]
  );

  const handleOpen = useCallback(
    (id) => {
      dispatch(setModalOpen("edit"));
      dispatch(setModalId(id));
    },
    [dispatch]
  );

  const setDocumentContext = useCallback(
    (employee, document, isExpanded = false) => {
      dispatch(
        setAppData({
          filial: selectedBranch,
          funcionario: { ...employee, idFuncionario: employee.funcionario },
          documento: document,
          filtros: {
            ...appData.filtros,
            documentos: documents,
            showingDocumentsFor: employee.funcionario,
            expandedDocumentsFor: isExpanded ? employee.funcionario : null,
          },
          contrato: null,
        })
      );
    },
    [appData.filtros, documents, dispatch, selectedBranch]
  );

  const handleDocumentOpen = useCallback(
    (employee, document, isExpanded = false) => {
      setDocumentContext(employee, document, isExpanded);
      history.push("/documentos/envios/");
    },
    [history, setDocumentContext]
  );

  const handleUploadOpen = useCallback(
    (employee, document, isExpanded = false) => {
      setDocumentContext(employee, document, isExpanded);
      dispatch(
        fetchEnvio({
          filial: appData.filial,
          funcionario: employee,
          constrato: null,
          documento: document,
        })
      );
      setUploadEmployee(employee);
      dispatch(setModalOpen("upload"));
    },
    [appData, dispatch, setDocumentContext]
  );

  const fetchEmployeeDocuments = useCallback(
    (employee) => {
      if (selectedBranch) {
        dispatch(
          fetchFuncionariosList(
            {
              filial: selectedBranch.idFilial,
              idsFilialFuncionario: [employee.idFilialFuncionario],
            },
            true,
            (data) => {
              updateEmployeeDocuments(employee.funcionario, data[0]);
              updateDocumentsAppData(employee.funcionario, data[0]);
            }
          )
        );
      }
      setFetchData(true);
      setFetchEmployees(true);

      if (!!employee.dataDemissao) setFetchFiredEmployees(true);
    },
    [dispatch, selectedBranch, updateDocumentsAppData, updateEmployeeDocuments]
  );

  const afterDismissAllIrregularDocuments = useCallback(
    (employee) => {
      dispatch(
        setFuncionarioId({
          id: employee.funcionario,
          fired: !!employee?.dataDemissao,
        })
      );

      MySwal.fire({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 2000,
        type: "success",
        title: "Documentos irregulares dispensados com sucesso",
      });

      fetchEmployeeDocuments(employee);
    },
    [dispatch, fetchEmployeeDocuments]
  );

  const handleIrregularDocumentsDismiss = useCallback(
    async (documents, employee, documnetsNotLoaded) => {
      MySwal.fire({
        title: "Dispensar documentos",
        type: "warning",
        html: `Deseja realmente dispensar todos os documentos irregulares e pendentes de <b>${employee.nome}</b>?`,
        showCancelButton: true,
        confirmButtonText: "Sim, dispensar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      }).then(async (result) => {
        console.log({ result, employee });
        if (!result.value) {
          return;
        }

        let documentsToDismiss = employee.irregulares || [];
        if (documentsToDismiss.length === 0) {
          if (documnetsNotLoaded) {
            const data = await fetchDocumentosFuncionariosList({
              filial: selectedBranch.idFilial,
              idsFilialFuncionario: [employee.idFilialFuncionario],
            });

            documentsToDismiss =
              data[0]?.documentos?.filter(isIrregularDocument) ?? [];
          }

          documentsToDismiss = documentsToDismiss.map(
            (document) => document.idDocumento
          );
        }

        dispatch(
          dismissDocument(
            {
              documentos: documentsToDismiss,
              funcionario: employee.funcionario,
              filial: selectedBranch.idFilial,
              contrato: null,
            },
            () => afterDismissAllIrregularDocuments(employee),
            false
          )
        );
      });
    },
    [afterDismissAllIrregularDocuments, dispatch, selectedBranch]
  );

  const filteredBranches = useMemo(
    () => branches.filter(handleBranchSearchFilter),
    [branches, handleBranchSearchFilter]
  );

  const handleEmployeeSortByPercentage = useCallback(
    (a, b) => (a.invalidos > b.invalidos ? -1 : 1),
    []
  );

  const handleAccordionPress = useCallback(() => {
    setFiredEmployeesAccordionOpen((s) => !s);
    setFetchFiredEmployees(true);
  }, []);

  const handleDocumentsAccordionToggle = useCallback(
    (key, employee) => {
      const isOpen = key !== null;

      if (!documents?.[employee.funcionario] || isOpen) {
        dispatch(
          fetchFuncionariosList(
            {
              filial: selectedBranch.idFilial,
              idsFilialFuncionario: [employee.idFilialFuncionario],
              ignorarPendentesDemitidos: true,
            },
            true,
            (data) => {
              updateEmployeeDocuments(employee.funcionario, data[0]);
              setShowingDocumentsFor((state) =>
                !isOpen
                  ? state.filter((id) => id !== employee.funcionario)
                  : [employee.funcionario]
              );
              setExpandedDocumentsFor((state) =>
                !isOpen
                  ? state.filter((id) => id !== employee.funcionario)
                  : state
              );
              setTimeout(() => {
                dispatch(
                  setAppData({
                    filtros: {
                      ...appData.filtros,
                      documentos: {
                        ...(appData.filtros.documentos ?? {}),
                        [employee.funcionario]: data[0] ?? {},
                      },
                      showingDocumentsFor: isOpen ? employee.funcionario : null,
                      expandedDocumentsFor: !isOpen
                        ? null
                        : expandedDocumentsFor,
                    },
                  })
                );
              }, 700);
            }
          )
        );
        return;
      }

      setShowingDocumentsFor((state) =>
        !isOpen
          ? state.filter((id) => id !== employee.funcionario)
          : [employee.funcionario]
      );
      setExpandedDocumentsFor((state) =>
        !isOpen ? state.filter((id) => id !== employee.funcionario) : state
      );
      setTimeout(() => {
        dispatch(
          setAppData({
            filtros: {
              ...appData.filtros,
              showingDocumentsFor: isOpen ? employee.funcionario : null,
              expandedDocumentsFor: !isOpen ? null : expandedDocumentsFor,
            },
          })
        );
      }, 700);
    },
    [
      documents,
      dispatch,
      selectedBranch,
      updateEmployeeDocuments,
      appData.filtros,
      expandedDocumentsFor,
    ]
  );

  const handleDocumentsExpand = useCallback((isOpen, employee) => {
    setExpandedDocumentsFor((state) =>
      isOpen
        ? [...state, employee.funcionario]
        : state.filter((id) => id !== employee.funcionario)
    );
  }, []);

  const handleEmployeeDocumentDismissal = useCallback(
    (row) => {
      MySwal.fire({
        title: "",
        type: "warning",
        html: (
          <DismissDocumentModal
            onConfirm={(justificativa) => {
              const values = {
                documento: row.original.idDocumento,
                funcionario: row.employee.funcionario,
                filial: selectedBranch?.idFilial,
                contrato: null,
                justificativa,
              };

              dispatch(
                dismissDocument(values, () => {
                  dispatch(
                    setFuncionarioId({
                      id: row.employee.funcionario,
                      fired: !!row.employee?.dataDemissao,
                    })
                  );

                  if (selectedBranch) {
                    dispatch(
                      fetchFuncionariosList(
                        {
                          filial: selectedBranch.idFilial,
                          idsFilialFuncionario: [
                            row.employee.idFilialFuncionario,
                          ],
                        },
                        true,
                        (data) => {
                          updateEmployeeDocuments(
                            row.employee.funcionario,
                            data[0]
                          );
                          updateDocumentsAppData(
                            row.employee.funcionario,
                            data[0]
                          );
                        }
                      )
                    );
                    setFetchData(true);
                    return;
                  }

                  setFetchData(true);
                })
              );
              MySwal.clickConfirm();
            }}
            onCancel={MySwal.clickCancel}
          />
        ),
        showCancelButton: false,
        showConfirmButton: false,
      });
    },
    [dispatch, selectedBranch, updateDocumentsAppData, updateEmployeeDocuments]
  );

  const handleContractsShow = useCallback(
    (props, employee) => {
      dispatch(
        fetch({
          filial: selectedBranch,
          funcionario: {
            ...employee,
            idFuncionario: employee.funcionario,
          },
          documento: props.original,
        })
      );
      dispatch(setModalOpen("contracts"));
    },
    [dispatch, selectedBranch]
  );

  const handleContractsPanelClose = useCallback(() => {
    dispatch(setModalOpen(""));
    dispatch(clearData());
  }, [dispatch]);

  useEffect(() => {
    if (fetchData) {
      setLoadingBranches(true);
      const loadData = async () => {
        loadBranches();
      };

      loadData().finally(() => setFetchData(false));
    }
  }, [fetchData, loadBranches]);

  useEffect(() => {
    if (selectedBranch && fetchEmployees) {
      setLoadingEmployees(true);

      const loadData = async () => {
        loadEmployees();
      };

      loadData().finally(() => setFetchEmployees(false));
    }
  }, [
    loadEmployees,
    loadBranches,
    fetchEmployees,
    loadFiredEmployees,
    selectedBranch,
  ]);

  useEffect(() => {
    if (scroll?.idFuncionario && scroll?.fired) {
      setFiredEmployeesAccordionOpen(true);
      setFetchFiredEmployees(true);
    }
  }, [scroll]);

  useEffect(() => {
    const firstBranch = appData.filtros?.filial ?? branches[0];

    if (!selectedBranch && branches.length && firstBranch) {
      setSelectedBranch(firstBranch);
    }

    const scrollTimeout = setTimeout(() => {
      if (
        !loadingBranches &&
        !loadingEmployees &&
        scroll?.idFuncionario &&
        employeeRef.current !== null
      ) {
        employeeRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });

        if (firstBranch) {
          const employee = employees[firstBranch.idFilial].find(
            (e) => e.funcionario === scroll.idFuncionario
          );

          if (employee) {
            dispatch(
              fetchFuncionariosList(
                {
                  filial: firstBranch.idFilial,
                  idsFilialFuncionario: [employee.idFilialFuncionario],
                },
                true,
                (data) => {
                  updateEmployeeDocuments(employee.funcionario, data[0]);
                  updateDocumentsAppData(
                    employee.employee.funcionario,
                    data[0]
                  );
                }
              )
            );
          }
        }

        dispatch(clearFuncionarioId());
      }
    }, 300);

    return () => clearTimeout(scrollTimeout);
  }, [
    employees,
    updateEmployeeDocuments,
    updateDocumentsAppData,
    appData.filtros,
    scroll,
    dispatch,
    selectedBranch,
    branches,
    loadingBranches,
    loadEmployees,
    loadingEmployees,
  ]);

  useEffect(() => {
    if (fetchFiredEmployees && selectedBranch) {
      setLoadingFiredEmployees(true);

      const loadData = async () => {
        loadFiredEmployees();
      };

      loadData().finally(() => {
        setLoadingEmployees(false);
        setFetchFiredEmployees(false);
      });
    }
  }, [firedEmployees, fetchFiredEmployees, loadFiredEmployees, selectedBranch]);

  useEffect(() => {
    setDocuments(appData.filtros?.documentos ?? {});
    setShowingDocumentsFor(
      appData.filtros?.showingDocumentsFor
        ? [appData.filtros.showingDocumentsFor]
        : []
    );
    setExpandedDocumentsFor(
      appData.filtros?.expandedDocumentsFor
        ? [appData.filtros.expandedDocumentsFor]
        : []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error && modal === "") {
    return <Error erro={error}></Error>;
  }

  if (loadingBranches || loadingEmployees || fetchEmployees) {
    return <LoadingImage />;
  }

  return (
    <div>
      <div className="row justify-content-center mx-0">
        <div className="col-12 px-0">
          <div className="card tertium-card">
            <div className="card-header py-0">
              <div className="row m-0">
                <div
                  className="col-12 col-lg-3 d-flex align-items-center justify-content-center py-4 py-lg-0"
                  style={{
                    borderRadius: "0",
                    boxShadow: "none",
                    borderTopLeftRadius: "calc(.25rem - 1px)",
                    backgroundColor: "#f15923",
                    borderBottom: "1px solid #cd4c1e",
                  }}
                >
                  <input
                    ref={branchInputRef}
                    className="transparent-input"
                    type="text"
                    title="Buscar filial..."
                    placeholder="Buscar filial..."
                    onChange={handleBranchSearch}
                  />
                  <img
                    src="/images/search.png"
                    alt="Pesquisar"
                    title="Pesquisar"
                    style={{
                      maxWidth: "18px",
                    }}
                  />
                </div>

                <div
                  className="col-12 col-lg-9 py-3 pl-2 pl-lg-5 d-flex justify-content-between align-items-center"
                  style={{ paddingRight: "120px" }}
                >
                  <div className="d-inline-block">
                    <span>{selectedBranch?.nome}</span>
                  </div>
                  <ButtonPrimary
                    title="Cadastrar novo funcionário"
                    onClick={() => {
                      dispatch(clearFuncionario());
                      dispatch(setModalOpen("new"));
                    }}
                    style={{
                      position: "absolute",
                      right: "0",
                      top: "0",
                      height: "100%",
                      borderRadius: "0",
                      boxShadow: "none",
                      borderTopRightRadius: "calc(.25rem - 1px)",
                      backgroundColor: "#f15923",
                      borderBottom: "1px solid #cd4c1e",
                      marginBottom: "-1px",
                    }}
                  >
                    <span className="d-none d-md-inline-block px-5 mx-3">
                      Novo funcionário
                    </span>
                    <img
                      src={"/images/plus.png"}
                      alt="Cadastrar novo funcionário"
                      title="Cadastrar novo funcionário"
                      className="img-fluid ml-0 plus-img"
                      style={{
                        maxWidth: "25px",
                        transform: "translateY(-3px)",
                        position: "absolute",
                        right: "20px",
                      }}
                    />
                  </ButtonPrimary>
                </div>
              </div>
            </div>
            <div className="card-body py-0">
              <div className="row mx-0 justify-content-center">
                <div
                  className="col-12 col-lg-3 py-3 custom-list overflow-on-md"
                  style={{
                    backgroundColor: COLOR_VERY_LIGHT,
                    fontSize: "14px",
                  }}
                >
                  {loadingBranches && (
                    <div className="col-12">Carregando...</div>
                  )}
                  {!loadingBranches && filteredBranches?.length > 0 ? (
                    filteredBranches?.map((branch, idx) => (
                      <div key={idx}>
                        <div
                          className="col-12 py-2"
                          style={{
                            borderBottom: "1px solid #e4e4e4",
                            cursor: "pointer",
                            background:
                              selectedBranch?.idFilial === branch.idFilial
                                ? "#e4e4e4"
                                : "transparent",
                          }}
                          onClick={() => {
                            handleBranchChange(branch);
                          }}
                          dangerouslySetInnerHTML={{
                            __html: branch.nome.toUpperCase(),
                          }}
                        ></div>
                      </div>
                    ))
                  ) : (
                    <div className="col-12">Nenhuma filial encontrada</div>
                  )}
                  {branchSearch && (
                    <button
                      type="button"
                      style={{
                        color: COLOR_DARK2,
                        boxShadow: "none",
                      }}
                      className="mx-auto d-block mt-4"
                      title="Limpar filtros"
                      onClick={handleClearBranchFilter}
                    >
                      <FontAwesomeIcon icon="times" /> Limpar filtros
                    </button>
                  )}
                </div>

                <div className="col-12 col-lg-9 px-2 px-lg-5 py-3 pb-5">
                  <div className="input-group mb-4">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text"
                        style={{
                          height: "38px",
                          backgroundColor: COLOR_PRIMARY,
                          borderColor: COLOR_PRIMARY,
                          color: "white",
                        }}
                        id="employee-search-addon"
                      >
                        <FontAwesomeIcon icon="search" />
                      </span>
                    </div>
                    <input
                      tabIndex={-1}
                      ref={employeeInputRef}
                      id="employee-search"
                      className="form-control"
                      placeholder="Pesquisa por nome, cargo ou CPF"
                      onChange={handleEmployeeSearch}
                    />
                  </div>
                  <EmployeesList
                    employees={branchEmployees
                      .sort(handleEmployeeSortByPercentage)
                      .filter(handleEmployeeSearchFilter)}
                    title="Funcionários"
                    employeesDocuments={documents ?? {}}
                    employeeRef={employeeRef}
                    onEdit={handleOpen}
                    onDocumentOpen={handleDocumentOpen}
                    onUploadOpen={handleUploadOpen}
                    showingDocumentsFor={showingDocumentsFor ?? []}
                    expandedDocumentsFor={expandedDocumentsFor ?? []}
                    onDocumentsAccordionToggle={handleDocumentsAccordionToggle}
                    onDocumentsExpand={handleDocumentsExpand}
                    onDocumentDismiss={handleEmployeeDocumentDismissal}
                    scroll={scroll}
                    onIrregularDocumentsDismiss={
                      handleIrregularDocumentsDismiss
                    }
                    onShowContracts={handleContractsShow}
                  />
                  <EmployeesList
                    className="mt-3"
                    disabledStyle
                    collapse
                    employees={branchFiredEmployees
                      .sort(handleEmployeeSortByPercentage)
                      .filter(handleEmployeeSearchFilter)}
                    title="Funcionários Demitidos"
                    employeesDocuments={documents ?? {}}
                    employeeRef={employeeRef}
                    onEdit={handleOpen}
                    onDocumentOpen={handleDocumentOpen}
                    onUploadOpen={handleUploadOpen}
                    scroll={scroll}
                    loading={loadingFiredEmployees}
                    onDocumentsAccordionToggle={handleDocumentsAccordionToggle}
                    onIrregularDocumentsDismiss={
                      handleIrregularDocumentsDismiss
                    }
                    onDocumentsExpand={handleDocumentsExpand}
                    showingDocumentsFor={showingDocumentsFor ?? []}
                    expandedDocumentsFor={expandedDocumentsFor ?? []}
                    onDocumentDismiss={handleEmployeeDocumentDismissal}
                    onToggle={handleAccordionPress}
                    isOpen={firedEmployeesAccordionOpen}
                    onShowContracts={handleContractsShow}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <RightPanel
        isPaneOpen={modal === "new"}
        title="Cadastro de Funcionário"
        path="new"
        onClose={() => {
          dispatch(setModalOpen(""));
        }}
      >
        <FuncionarioForm onAfterSave={loadEmployees}></FuncionarioForm>
      </RightPanel>

      <RightPanel
        isPaneOpen={modal === "edit" && id !== null}
        title="Edição de Funcionário"
        path="edit"
        onClose={() => {
          dispatch(setModalOpen(""));
        }}
      >
        <FuncionarioForm
          onAfterSave={handleAfterSave}
          id={id}
        ></FuncionarioForm>
      </RightPanel>

      <RightPanel
        isPaneOpen={modal === "upload"}
        title="Upload de Arquivo"
        path="upload"
        onClose={() => {
          dispatch(setAppData({ envio: null }));
          dispatch(setModalOpen(""));
        }}
      >
        {appData?.funcionario?.idFuncionario ? (
          <Upload
            onAfterSave={() => {
              if (!uploadEmployee) return;

              fetchEmployeeDocuments(uploadEmployee);
              dispatch(
                setFuncionarioId({
                  id: uploadEmployee.funcionario,
                  fired: !!uploadEmployee?.dataDemissao,
                })
              );
            }}
          />
        ) : (
          <LoadingImage />
        )}
      </RightPanel>

      <RightPanel
        isPaneOpen={modal === "contracts"}
        title="Contratos"
        path="contracts"
        onClose={handleContractsPanelClose}
      >
        {envioLoading ? <LoadingImage /> : <ContractsTable envio={envio} />}
      </RightPanel>
    </div>
  );
};

VisaoTomadorFuncionarios.propTypes = {
  modal: PropTypes.string.isRequired,
  id: PropTypes.any,
  funcionarios: PropTypes.array,
  permissoes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  modal: state.modal.modalOpen,
  id: state.modal.id,
  appData: state.dados.data,
  envio: state.envios.envio,
  envioLoading: state.envios.loading,
  error: state.errors.message,
  funcionarios: state.documentosFuncionarios.funcionarios,
  funcionariosRemovidos:
    state.documentosFuncionariosRemovidos.funcionariosRemovidos,
  permissoes: state.permissions.permissoes,
  scroll: state.scroll,
});

export default connect(mapStateToProps)(withRouter(VisaoTomadorFuncionarios));
