/* Geral / Bibliotecas */
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import moment from "moment";
import RightPanel from "../Sidepanel/RightPanel";
import ReactTable from "react-table";
import "react-table/react-table.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

/* Métodos / Ações */
import { fetch } from "../../actions/envios";
import { setModalOpen } from "../../actions/modal";
import { clearComentario } from "../../actions/comentarios";
import { setFuncionarioId } from "../../actions/scroll";

/* Cores */
import {
    COLOR_DARK2,
    COLOR_GREEN,
    COLOR_WARNING,
    COLOR_INFO,
    COLOR_DANGER,
} from "../../Colors";

/* Componentes */
import Error from "../Error";
import LoadingImage from "../LoadingImage";
import Upload from "./Upload";
import ComentarioForm from "../Comentario/ComentarioForm";

/* Botões */
import ButtonPrimary from "../Buttons/ButtonPrimary";
import ButtonBack from "../Buttons/ButtonBack";
import { setFilialFuncionarioId } from "../../actions/filialFuncionario";
import EnvioArquivo from "./EnvioArquivo";
import { isAuditoria } from "../../helpers/isAuditoria";
import { STATUS } from "../../types/StatusContrato";

const MySwal = withReactContent(Swal);

class Envios extends Component {
    constructor() {
        super();

        this._isMounted = false;
        this.state = {
            modalOpen: "null",
            qtdModalOpen: 1,
            expand: null,
            tmpContrato: null,
            contrato: null,
        };
        this.handleOpen = this.handleOpen.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        setTimeout(() => {
            if (
                this.props.dados === null ||
                this.props.dados.documento === null
            ) {
                this.props.history.push("/documentos/");
            }
        }, 1000);

        if (this.props.dados && this.props.dados.documento)
            this.props.dispatch(fetch(this.props.dados));

        // Se tiver um filtro de contrato e vir pelo envelope abrir a modal
        if (
            this.props.dados.filtros &&
            this.props.dados.filtros.contrato &&
            window.location.hash === "#comments"
        ) {
            this.setState({
                contrato: this.props.dados.filtros.contrato.idContrato,
            });
            this.props.dispatch(setModalOpen("comments"));
        }

        if (this.props.dados.funcionario?.idFuncionario) {
            this.props.dispatch(
                setFuncionarioId({
                    id: this.props.dados.funcionario.idFuncionario,
                    fired: !!this.props.dados.funcionario.dataDemissao,
                })
            );
        }

        if (this.props.dados.funcionario?.idFilialFuncionario) {
            this.props.dispatch(
                setFilialFuncionarioId(
                    this.props.dados.funcionario.idFilialFuncionario
                )
            );
        }
    }

    componentDidUpdate(props) {
        if (
            this.props.envio.contratos?.length > 0 &&
            this.props.envio.contratos?.length !==
                props.envio.contratos?.length &&
            this.state.expand === null
        ) {
            this.setState({
                expand: "contratos",
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleOpen() {
        if (
            isAuditoria(this.props.dados.funcionario, this.props.envio.status)
        ) {
            MySwal.fire({
                type: "info",
                text: "DOCUMENTO ESTÁ DISPONÍVEL APENAS PARA AUDITORIA, NOVAS VERSÕES NÃO PODEM SER ENVIADAS",
                toast: true,
                showConfirmButton: false,
                timer: 4000,
            });
            return;
        }

        this.setState({
            modalOpen: "upload",
            qtdModalOpen: this.state.qtdModalOpen + 1,
        });
        this.props.dispatch(setModalOpen("upload"));
    }

    render() {
        if (this.props.error && this.props.modal === "") {
            return <Error erro={this.props.error}></Error>;
        }
        if (
            this.props.dados === null ||
            this.props.dados.documento === null ||
            this.props.loading
        ) {
            return <LoadingImage></LoadingImage>;
        }
        if (
            this.props.dados.contrato &&
            this.props.dados.contrato.tipo !== "terceiro"
        ) {
            return (
                <Error
                    erro={
                        "Parece que você não é terceiro deste contrato, você não deveria estar nesta tela, caso queira enviar documentos vá para a aba Gestão, ou ainda, Documentos, caso o problema persista entre em contato."
                    }
                ></Error>
            );
        }

        const color =
            this.props.envio?.porcentagem < 100
                ? this.props.envio.invalidos > 0
                    ? COLOR_DANGER
                    : COLOR_WARNING
                : COLOR_GREEN;
        const left =
            this.props.envio?.porcentagem < 5
                ? 5
                : this.props.envio?.porcentagem > 95
                ? 95
                : this.props.envio?.porcentagem;

        const a = this.props.envio.validade
            ? moment(this.props.envio.validade.date).startOf("day")
            : moment().startOf("day");
        const b = moment().startOf("day");

        const diffDuration = moment.duration(a.diff(b));

        const anosRestantes = this.props.envio.validade
            ? diffDuration.years()
            : 0;
        const mesesRestantes = this.props.envio.validade
            ? diffDuration.months()
            : 0;
        const diasRestantes = this.props.envio.validade
            ? diffDuration.days()
            : 0;

        const filial = this.props.dados.filial
            ? this.props.dados.filial
            : this.props.usuarioLogado.filial;

        const contrato = this.props.dados.contrato;

        const bloqueado =
            contrato &&
            contrato.tipo !== "tomador" &&
            contrato.status === STATUS.INATIVO;
        const vencido =
            contrato &&
            moment(contrato.dataFim.date).diff(
                moment().startOf("day"),
                "days"
            ) <= 0;

        const envioButtonProps =
            bloqueado || vencido
                ? {
                      title: `Contrato ${bloqueado ? "bloqueado" : "vencido"}`,
                      onClick: () =>
                          MySwal.fire({
                              type: "warning",
                              title: bloqueado
                                  ? "CONTRATO BLOQUEADO PELO TOMADOR"
                                  : null,
                              html: bloqueado
                                  ? "Caso necessite fazer upload de algum documento, solicitar a liberação de acesso"
                                  : `
                            CONTRATO <strong>${contrato?.nome?.toUpperCase()}</strong> ESTÁ VENCIDO.<BR/>
                            SOLICITE QUE A EMPRESA TOMADORA LIBERE O ACESSO
                        `,
                          }),
                  }
                : { title: "Enviar Novo Documento", onClick: this.handleOpen };

        return (
            <div>
                <div className="row justify-content-center mx-0">
                    <div className="col-12 px-0">
                        <div className="card tertium-card">
                            <div className="card-header py-3 px-2 px-lg-5">
                                <ButtonBack
                                    modal={this.state.qtdModalOpen}
                                ></ButtonBack>
                                {!this.props.dados.contrato && (
                                    <div>Envios</div>
                                )}
                                {this.props.dados.contrato && (
                                    <div>
                                        <img
                                            src={
                                                this.props.dados.filtros.empresa
                                                    .logo
                                                    ? this.props.dados.filtros
                                                          .empresa.logo
                                                    : "/images/filial.png"
                                            }
                                            alt={
                                                this.props.dados.filtros.empresa
                                                    .nome
                                            }
                                            className="img-fluid rounded-circle ml-2"
                                            style={{ height: "40px" }}
                                        />
                                        &nbsp;
                                        {this.props.dados.filtros.empresa.nome}
                                    </div>
                                )}
                                <ButtonPrimary
                                    style={{
                                        position: "absolute",
                                        right: "0",
                                        top: "0",
                                        height: "100%",
                                        borderRadius: "0",
                                        boxShadow: "none",
                                        borderTopRightRadius:
                                            "calc(.25rem - 1px)",
                                        backgroundColor: "#f15923",
                                        borderBottom: "1px solid #cd4c1e",
                                        marginBottom: "-1px",
                                    }}
                                    {...envioButtonProps}
                                >
                                    <span className="d-none d-md-inline-block px-5 mx-3">
                                        Novo Envio
                                    </span>
                                    <img
                                        src={"/images/plus.png"}
                                        alt="Novo envio"
                                        title="Novo envio"
                                        className="img-fluid ml-0 plus-img"
                                        style={{
                                            maxWidth: "25px",
                                            transform: "translateY(-3px)",
                                            position: "absolute",
                                            right: "20px",
                                        }}
                                    />
                                </ButtonPrimary>
                            </div>
                            <div className="card-body pb-0">
                                <div
                                    className="row mx-0 py-4 px-2 px-lg-5"
                                    style={{
                                        borderBottom:
                                            this.state.expand === null
                                                ? "none"
                                                : "1px solid #cccccc",
                                    }}
                                >
                                    <div className="col-12 col-lg-7 d-flex flex-column">
                                        <h4
                                            className="font-weight-400"
                                            style={{ color: COLOR_DARK2 }}
                                        >
                                            {this.props.dados.documento.nome}
                                        </h4>
                                        {this.props.envio.status !== 0 && (
                                            <div
                                                style={{
                                                    fontSize: "15px",
                                                    color:
                                                        anosRestantes >= 1 ||
                                                        mesesRestantes >= 1 ||
                                                        diasRestantes >= 1
                                                            ? COLOR_GREEN
                                                            : COLOR_WARNING,
                                                }}
                                                className="font-weight-500"
                                            >
                                                <FontAwesomeIcon icon="calendar-alt" />{" "}
                                                VENCIMENTO:{" "}
                                                {moment(
                                                    this.props.envio.validade
                                                        .date
                                                ).format("DD/MM/YYYY")}
                                                &nbsp;
                                                {(anosRestantes >= 1 ||
                                                    diasRestantes >= 1 ||
                                                    mesesRestantes >= 1) && (
                                                    <span
                                                        style={{
                                                            color: "#666666",
                                                            fontWeight: "400",
                                                        }}
                                                    >
                                                        (
                                                        {anosRestantes > 1 &&
                                                            anosRestantes +
                                                                " anos, "}
                                                        {anosRestantes === 1 &&
                                                            "1 ano, "}
                                                        {mesesRestantes > 1 &&
                                                            mesesRestantes +
                                                                " meses e "}
                                                        {mesesRestantes === 1 &&
                                                            "1 mês e "}
                                                        {diasRestantes > 1 &&
                                                            diasRestantes +
                                                                " dias restantes"}
                                                        {diasRestantes === 1 &&
                                                            "1 dia restante "}
                                                        )
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                        {this.props.dados.funcionario && (
                                            <div
                                                style={{
                                                    fontSize: "15px",
                                                }}
                                                className="font-weight-500"
                                            >
                                                <FontAwesomeIcon icon="user-friends" />{" "}
                                                FUNCIONÁRIO:{" "}
                                                <b>
                                                    {
                                                        this.props.dados
                                                            .funcionario.nome
                                                    }
                                                </b>
                                            </div>
                                        )}
                                        {this.props.dados.contrato && (
                                            <div
                                                style={{
                                                    fontSize: "15px",
                                                }}
                                                className="font-weight-500"
                                            >
                                                <FontAwesomeIcon icon="signature" />{" "}
                                                CONTRATO:{" "}
                                                <b>
                                                    {
                                                        this.props.dados
                                                            .contrato.nome
                                                    }
                                                </b>
                                            </div>
                                        )}
                                        {this.props.envio.idDocumentoEnvio &&
                                            !this.props.envio.ultimo &&
                                            this.state.expand === null && (
                                                <div
                                                    style={{
                                                        fontSize: "15px",
                                                        color: COLOR_DANGER,
                                                    }}
                                                    className="font-weight-500 cursor-pointer"
                                                    onClick={() => {
                                                        this.setState({
                                                            expand: "contratos",
                                                        });
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon="exclamation-triangle" />{" "}
                                                    <b>ATENÇÃO</b>: Você não
                                                    está vendo a última versão
                                                    deste documento, clique aqui
                                                    para ver todas as emissões
                                                </div>
                                            )}
                                    </div>
                                    <div className="col-12 col-lg-4">
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={
                                                <Popover
                                                    placement="bottom"
                                                    id="popover-basic"
                                                    title={
                                                        this.props.envio
                                                            ?.porcentagem < 100
                                                            ? "Como melhorar?"
                                                            : "Tudo certo"
                                                    }
                                                >
                                                    {this.props.envio
                                                        ?.porcentagem < 100 && (
                                                        <div>
                                                            {this.props.envio
                                                                .status ===
                                                                0 && (
                                                                <div
                                                                    style={{
                                                                        color: COLOR_WARNING,
                                                                    }}
                                                                >
                                                                    <FontAwesomeIcon icon="cloud-upload-alt" />{" "}
                                                                    <b>
                                                                        Documento
                                                                        Pendente
                                                                    </b>
                                                                    : Para
                                                                    solucionar
                                                                    este
                                                                    problema
                                                                    deve ser
                                                                    enviada uma
                                                                    versão, que
                                                                    irá para a
                                                                    análise dos
                                                                    contratantes
                                                                </div>
                                                            )}
                                                            {this.props.envio
                                                                .status ===
                                                                1 && (
                                                                <div
                                                                    style={{
                                                                        color: COLOR_WARNING,
                                                                    }}
                                                                >
                                                                    <FontAwesomeIcon icon="clock" />{" "}
                                                                    <b>
                                                                        Documento
                                                                        Expirando
                                                                    </b>
                                                                    : Para
                                                                    evitar
                                                                    problemas, o
                                                                    documento
                                                                    deve ser
                                                                    reenviado em
                                                                    breve
                                                                </div>
                                                            )}
                                                            {this.props.envio
                                                                .status ===
                                                                2 && (
                                                                <div
                                                                    style={{
                                                                        color: COLOR_INFO,
                                                                    }}
                                                                >
                                                                    <FontAwesomeIcon icon="hourglass-half" />{" "}
                                                                    <b>
                                                                        Documento
                                                                        Aguardando
                                                                        Avaliação
                                                                    </b>
                                                                    : Para
                                                                    solucionar
                                                                    este
                                                                    problema o
                                                                    documento
                                                                    deve ser
                                                                    avaliado por
                                                                    todos os
                                                                    contratantes
                                                                </div>
                                                            )}
                                                            {this.props.envio
                                                                .status ===
                                                                -1 && (
                                                                <div
                                                                    style={{
                                                                        color: COLOR_DANGER,
                                                                    }}
                                                                >
                                                                    <FontAwesomeIcon icon="exclamation-circle" />{" "}
                                                                    <b>
                                                                        Documento
                                                                        Expirado
                                                                    </b>
                                                                    : Para
                                                                    solucionar
                                                                    este
                                                                    problema
                                                                    deve ser
                                                                    enviada uma
                                                                    nova versão,
                                                                    que irá
                                                                    novamente
                                                                    para a
                                                                    análise dos
                                                                    contratantes
                                                                </div>
                                                            )}
                                                            {this.props.envio
                                                                .status ===
                                                                -2 && (
                                                                <div
                                                                    style={{
                                                                        color: COLOR_DANGER,
                                                                    }}
                                                                >
                                                                    <FontAwesomeIcon icon="ban" />{" "}
                                                                    <b>
                                                                        Documento
                                                                        Recusado
                                                                    </b>
                                                                    : Para
                                                                    solucionar
                                                                    este
                                                                    problema
                                                                    existem duas
                                                                    opções,
                                                                    sendo que é
                                                                    possível
                                                                    enviar o
                                                                    documento
                                                                    novamente
                                                                    somente para
                                                                    o contrato
                                                                    que o
                                                                    recusou,
                                                                    como também
                                                                    é possível
                                                                    enviar para
                                                                    todos os
                                                                    contratos
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                    {this.props.envio
                                                        ?.porcentagem >=
                                                        100 && (
                                                        <div
                                                            style={{
                                                                color: COLOR_GREEN,
                                                            }}
                                                        >
                                                            <FontAwesomeIcon icon="check" />{" "}
                                                            Nenhuma pendência
                                                            neste documento
                                                        </div>
                                                    )}
                                                </Popover>
                                            }
                                        >
                                            <div className="progress mt-4">
                                                <div
                                                    className="progress-bar"
                                                    style={{
                                                        background: color,
                                                        width:
                                                            this.props.envio
                                                                ?.porcentagem +
                                                            "%",
                                                    }}
                                                    role="progressbar"
                                                    aria-valuenow={
                                                        this.props.envio
                                                            ?.porcentagem
                                                    }
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"
                                                    id="progressbar"
                                                >
                                                    <span
                                                        className="progress-indicator"
                                                        style={{
                                                            backgroundColor:
                                                                color,
                                                            borderColor: color,
                                                            left: left + "%",
                                                        }}
                                                    >
                                                        {
                                                            this.props.envio
                                                                ?.porcentagem
                                                        }
                                                        %
                                                    </span>
                                                </div>
                                            </div>
                                        </OverlayTrigger>
                                        <div
                                            className="w-100 text-center font-weight-500 pt-2"
                                            style={{
                                                color: color,
                                                fontSize: "15px",
                                            }}
                                        >
                                            {this.props.envio.status === 0 &&
                                                "Documento pendente (Não enviado)"}
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-1 d-flex align-items-center justify-content-center">
                                        <button
                                            style={{
                                                backgroundColor: "#696969",
                                                color: "#dcdcdc",
                                                fontSize: "2.5em",
                                                borderRadius: "50%",
                                                display: "flex",
                                            }}
                                            onClick={() => {
                                                if (
                                                    this.state.expand ===
                                                    "contratos"
                                                ) {
                                                    this.setState({
                                                        expand: null,
                                                    });
                                                } else {
                                                    this.setState({
                                                        expand: "contratos",
                                                    });
                                                }
                                            }}
                                        >
                                            {this.state.expand ===
                                                "contratos" && (
                                                <FontAwesomeIcon
                                                    style={{ margin: "-1px" }}
                                                    icon="chevron-circle-up"
                                                />
                                            )}
                                            {this.state.expand !==
                                                "contratos" && (
                                                <FontAwesomeIcon
                                                    style={{ margin: "-1px" }}
                                                    icon="chevron-circle-down"
                                                />
                                            )}
                                        </button>
                                    </div>
                                </div>
                                {this.state.expand === "contratos" && (
                                    <div className="row mx-0 py-4 px-2 px-lg-5">
                                        <div className="col-12 mb-3">
                                            <h5
                                                className="font-weight-400"
                                                style={{ color: COLOR_DARK2 }}
                                            >
                                                Emissões:
                                            </h5>
                                            {this.props.envio.versoes
                                                ?.length === 0 &&
                                                this.props.envio.status !==
                                                    0 && (
                                                    <p>
                                                        Este documento só tem
                                                        uma emissão (Em
                                                        exibição)
                                                    </p>
                                                )}
                                            {this.props.envio.versoes
                                                ?.length === 0 &&
                                                this.props.envio.status ===
                                                    0 && (
                                                    <p>
                                                        Este documento nunca foi
                                                        enviado
                                                    </p>
                                                )}
                                            {this.props.envio.versoes?.length >
                                                0 && (
                                                <div className="w-100">
                                                    {this.props.envio.versoes?.map(
                                                        (val, idx) => {
                                                            return (
                                                                <div
                                                                    key={idx}
                                                                    className="cursor-pointer mr-2"
                                                                    style={{
                                                                        backgroundColor:
                                                                            parseInt(
                                                                                this
                                                                                    .props
                                                                                    .envio
                                                                                    .idDocumentoEnvio
                                                                            ) ===
                                                                                parseInt(
                                                                                    val.idDocumentoEnvio
                                                                                ) ||
                                                                            (this
                                                                                .props
                                                                                .envio
                                                                                .idDocumentoEnvio ===
                                                                                null &&
                                                                                idx ===
                                                                                    0)
                                                                                ? COLOR_INFO
                                                                                : COLOR_DARK2,
                                                                        color: "white",
                                                                        fontWeight: 500,
                                                                        borderRadius:
                                                                            "20px",
                                                                        padding:
                                                                            "5px 25px",
                                                                        display:
                                                                            "inline-block",
                                                                    }}
                                                                    onClick={() => {
                                                                        this.props.dispatch(
                                                                            fetch(
                                                                                this
                                                                                    .props
                                                                                    .dados,
                                                                                val.idDocumentoEnvio
                                                                            )
                                                                        );
                                                                    }}
                                                                >
                                                                    {moment(
                                                                        val.data
                                                                            .date
                                                                    ).format(
                                                                        "DD/MM/YYYY"
                                                                    )}
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            )}
                                        </div>

                                        <div className="col-12">
                                            <h5
                                                className="font-weight-400"
                                                style={{ color: COLOR_DARK2 }}
                                            >
                                                Contratos:
                                            </h5>
                                            {this.props.envio.contratos
                                                ?.length === 0 &&
                                                this.props.envio.versoes
                                                    ?.length === 0 &&
                                                this.props.envio.status ===
                                                    0 && (
                                                    <p>
                                                        Este documento ainda não
                                                        foi enviado
                                                    </p>
                                                )}
                                            {this.props.envio.contratos
                                                ?.length === 0 &&
                                                this.props.envio.status !==
                                                    0 && (
                                                    <p>
                                                        Este documento não faz
                                                        parte de nenhum contrato
                                                    </p>
                                                )}
                                            {this.props.envio.contratos
                                                ?.length > 0 && (
                                                <div className="w-100">
                                                    <ReactTable
                                                        columns={[
                                                            {
                                                                Header: "Título",
                                                                accessor:
                                                                    "titulo",
                                                                headerClassName:
                                                                    "",
                                                                filterable: false,
                                                                className:
                                                                    "font-weight-600",
                                                                Cell: (
                                                                    props
                                                                ) => {
                                                                    return (
                                                                        <div>
                                                                            {
                                                                                props.value
                                                                            }
                                                                        </div>
                                                                    );
                                                                },
                                                            },
                                                            {
                                                                Header: "Tomador",
                                                                accessor:
                                                                    "tomador",
                                                                headerClassName:
                                                                    "",
                                                                filterable: false,
                                                                className:
                                                                    "font-weight-600",
                                                                Cell: (
                                                                    props
                                                                ) => {
                                                                    return (
                                                                        <div>
                                                                            {
                                                                                props.value
                                                                            }
                                                                        </div>
                                                                    );
                                                                },
                                                            },
                                                            {
                                                                Header: "Data Inicial",
                                                                accessor:
                                                                    "dataInicio.date",
                                                                filterable: false,
                                                                maxWidth: 120,
                                                                Cell: (
                                                                    props
                                                                ) => {
                                                                    if (
                                                                        props.value
                                                                    ) {
                                                                        return (
                                                                            <div>
                                                                                {moment(
                                                                                    props.value
                                                                                ).format(
                                                                                    "DD/MM/YYYY"
                                                                                )}
                                                                            </div>
                                                                        );
                                                                    } else {
                                                                        return (
                                                                            <div>
                                                                                --/--/---
                                                                            </div>
                                                                        );
                                                                    }
                                                                },
                                                            },
                                                            {
                                                                Header: "Data Final",
                                                                accessor:
                                                                    "dataFim.date",
                                                                filterable: false,
                                                                maxWidth: 120,
                                                                Cell: (
                                                                    props
                                                                ) => {
                                                                    if (
                                                                        props.value
                                                                    ) {
                                                                        return (
                                                                            <div>
                                                                                {moment(
                                                                                    props.value
                                                                                ).format(
                                                                                    "DD/MM/YYYY"
                                                                                )}
                                                                            </div>
                                                                        );
                                                                    } else {
                                                                        return (
                                                                            <div>
                                                                                --/--/---
                                                                            </div>
                                                                        );
                                                                    }
                                                                },
                                                            },
                                                            {
                                                                Header: "Situação",
                                                                accessor:
                                                                    "status",
                                                                filterable: false,
                                                                maxWidth: 150,
                                                                Cell: (
                                                                    props
                                                                ) => {
                                                                    if (
                                                                        this
                                                                            .props
                                                                            .envio
                                                                            .status ===
                                                                        -1
                                                                    ) {
                                                                        return (
                                                                            <div
                                                                                style={{
                                                                                    color: COLOR_DANGER,
                                                                                }}
                                                                            >
                                                                                <FontAwesomeIcon icon="exclamation-circle" />{" "}
                                                                                Expirado
                                                                            </div>
                                                                        );
                                                                    }
                                                                    switch (
                                                                        props.value
                                                                    ) {
                                                                        case -1: // Recusado
                                                                            return (
                                                                                <div
                                                                                    className="cursor-pointer"
                                                                                    onClick={() => {
                                                                                        MySwal.fire(
                                                                                            {
                                                                                                title: "",
                                                                                                type: "warning",
                                                                                                html: `<h5 style="color:${COLOR_DANGER}">Recusado</h5>
                                                                                        <b>Justificativa:</b><br>
                                                                                        ${props.original.justificativa}`,
                                                                                                showCancelButton: true,
                                                                                                confirmButtonText:
                                                                                                    "Enviar novamente",
                                                                                                cancelButtonText:
                                                                                                    "Cancelar",
                                                                                                reverseButtons: true,
                                                                                            }
                                                                                        ).then(
                                                                                            (
                                                                                                result
                                                                                            ) => {
                                                                                                if (
                                                                                                    result.value
                                                                                                ) {
                                                                                                    this.setState(
                                                                                                        {
                                                                                                            tmpContrato:
                                                                                                                props.original,
                                                                                                        }
                                                                                                    );
                                                                                                    this.handleOpen();
                                                                                                }
                                                                                            }
                                                                                        );
                                                                                    }}
                                                                                    style={{
                                                                                        color: COLOR_DANGER,
                                                                                    }}
                                                                                >
                                                                                    <FontAwesomeIcon icon="ban" />{" "}
                                                                                    Recusado{" "}
                                                                                    <FontAwesomeIcon icon="question-circle" />
                                                                                </div>
                                                                            );
                                                                        case 0: // Aguardando avaliação
                                                                            return (
                                                                                <div
                                                                                    style={{
                                                                                        color: COLOR_INFO,
                                                                                    }}
                                                                                >
                                                                                    <FontAwesomeIcon icon="hourglass-half" />{" "}
                                                                                    Ag.
                                                                                    Avaliação
                                                                                </div>
                                                                            );
                                                                        case 1: // Aprovado
                                                                            return (
                                                                                <div
                                                                                    style={{
                                                                                        color: COLOR_GREEN,
                                                                                    }}
                                                                                >
                                                                                    <FontAwesomeIcon icon="check" />{" "}
                                                                                    Regular
                                                                                </div>
                                                                            );
                                                                        default:
                                                                            return null;
                                                                    }
                                                                },
                                                            },
                                                            {
                                                                Header: "Comentários",
                                                                accessor:
                                                                    "comentarios",
                                                                filterable: false,
                                                                maxWidth: 150,
                                                                Cell: (
                                                                    props
                                                                ) => {
                                                                    return (
                                                                        <div>
                                                                            {
                                                                                props.value
                                                                            }
                                                                            <span className="d-inline-block d-md-none pl-2">
                                                                                Comentário(s)
                                                                            </span>
                                                                            <button
                                                                                className="a ml-4"
                                                                                onClick={() => {
                                                                                    this.setState(
                                                                                        {
                                                                                            contrato:
                                                                                                props
                                                                                                    .original
                                                                                                    .idContrato,
                                                                                        }
                                                                                    );
                                                                                    this.props.dispatch(
                                                                                        setModalOpen(
                                                                                            "comments"
                                                                                        )
                                                                                    );
                                                                                }}
                                                                                title="Ver"
                                                                            >
                                                                                <FontAwesomeIcon icon="envelope" />
                                                                            </button>
                                                                        </div>
                                                                    );
                                                                },
                                                            },
                                                        ]}
                                                        multiSort={false}
                                                        data={
                                                            this.props.envio
                                                                .contratos
                                                        }
                                                        filterable={false}
                                                        resizable={false}
                                                        noDataText="Nenhum contrato encontrado"
                                                        pageText="Página"
                                                        ofText="de"
                                                        rowsText="linhas"
                                                        pageJumpText="ir para a página"
                                                        rowsSelectorText="linhas por página"
                                                        previousText={
                                                            <FontAwesomeIcon icon="chevron-left" />
                                                        }
                                                        nextText={
                                                            <FontAwesomeIcon icon="chevron-right" />
                                                        }
                                                        pageSizeOptions={[
                                                            5, 10, 20, 50,
                                                        ]}
                                                        defaultPageSize={10}
                                                        minRows={0}
                                                        className="-highlight mb-3"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                                {this.props.envio.idDocumentoEnvio &&
                                    this._isMounted && (
                                        <div className="row mx-0">
                                            <div className="col-12 px-0">
                                                <EnvioArquivo
                                                    envio={this.props.envio}
                                                    filial={filial.idFilial}
                                                    documento={
                                                        this.props.dados
                                                            .documento
                                                    }
                                                    funcionario={
                                                        this.props.dados
                                                            .funcionario
                                                            ?.idFuncionario
                                                    }
                                                    contrato={
                                                        this.props.dados.filtros
                                                            .contrato
                                                            ?.idContrato
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Formulário de Criação */}
                <RightPanel
                    isPaneOpen={this.props.modal === "upload"}
                    title="Upload de Arquivo"
                    path="upload"
                    onClose={() => {
                        this.props.dispatch(setModalOpen(""));
                    }}
                >
                    <Upload
                        modal={this.state.qtdModalOpen}
                        contrato={
                            this.state.tmpContrato
                                ? this.state.tmpContrato.idContrato
                                : null
                        }
                    />
                </RightPanel>
                <RightPanel
                    isPaneOpen={this.props.modal === "comments"}
                    title="Comentários"
                    path="comments"
                    onClose={() => {
                        this.props.dispatch(setModalOpen(""));
                        this.props.dispatch(clearComentario());
                    }}
                >
                    <ComentarioForm
                        tipoContrato="tomador"
                        contrato={this.state.contrato}
                        onAfterSave={this.requestData}
                    ></ComentarioForm>
                </RightPanel>
            </div>
        );
    }
}
Envios.propTypes = {
    modal: PropTypes.string.isRequired,
    envio: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    dados: PropTypes.any,
};

const mapStateToProps = (state) => ({
    modal: state.modal.modalOpen,
    dados: state.dados.data,
    error: state.errors.message ? state.errors.message : state.envios.error,
    envio: state.envios.envio,
    usuarioLogado: state.usuarioLogado.dados,
    loading: state.envios.loading,
});

export default connect(mapStateToProps)(withRouter(Envios));
