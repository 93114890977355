/* Geral / Bibliotecas */
import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import ReactTable from "react-table";
import "react-table/react-table.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Accordion from "react-bootstrap/Accordion";

/* Métodos / Ações */
import {
  fetchList,
  fetchContrato,
  solicitaMudancaContrato,
  aprovaMudancaContrato,
  rejeitaMudancaContrato,
} from "../../actions/gestaoDocumentos";
import {
  loadFiliaisDocumentosOptions,
  fetchFuncionariosList,
  fetchFuncionariosRemovidosList,
  fetchContratoList,
  fetchList as fetchDocumentosList,
  bulkDownload,
  dismissDocument,
  fetchDocumentosFuncionario,
  fetchDocumentosFuncionarioRemovido,
} from "../../actions/documentos";
import { setAppData } from "../../actions/dados";
import { clearData } from "../../actions/contratos";
import {
  setModalOpen,
  setModalId,
  setModalExtraData,
} from "../../actions/modal";
import withReactContent from "sweetalert2-react-content";
import MySwal from "sweetalert2";
import { clearComentario } from "../../actions/comentarios";
import { debounce } from "../../helpers/debounce";

/* Cores */
import {
  COLOR_VERY_LIGHT,
  COLOR_DARK2,
  COLOR_WARNING,
  COLOR_GREEN,
  COLOR_INFO,
  COLOR_GRAY2,
  COLOR_VERY_LIGHT_BLUE,
  COLOR_DANGER,
  COLOR_PRIMARY,
  COLOR_ORANGE,
} from "../../Colors";

/* Componentes */
import Error from "../Error";
import LoadingImage from "../LoadingImage";
import RightPanel from "../Sidepanel/RightPanel";
import ContratoForm from "../Contrato/ContratoForm";
import ContratoDocumentos from "../Contrato/ContratoDocumentos";
import ContratoFuncionarios from "../Contrato/ContratoFuncionarios";
import ComentarioForm from "../Comentario/ComentarioForm";
import AlertSemPlano from "../Contrato/AlertSemPlano";
import DismissDocumentModal from "./DismissDocumentModal";

/* Botões */
import ButtonPrimary from "../Buttons/ButtonPrimary";
import ButtonSuccess from "../Buttons/ButtonSuccess";

/* Estilos */
import "./GestaoDocumentos.css";
import { setFuncionarioId } from "../../actions/scroll";
import DocumentosFuncionarioTable, {
  DEMITIDO,
} from "../Table/DocumentosFuncionarioTable";
import BaixarDocumentosModal from "./BaixarDocumentosModal";
import { setFilialFuncionarioId } from "../../actions/filialFuncionario";
import { REFRESH_KEY, clearRefresh, setRefresh } from "../../actions/refresh";
import Upload from "../Documento/Upload";
import UploadColumn from "../Table/CellDef/UploadColumn";
import { fetch as fetchEnvio } from "../../actions/envios";
import { STATUS } from "../../types/StatusContrato";
import ButtonGray from "../Buttons/ButtonGray";
import DocumentsPortability from "../DocumentsPortability/DocumentsPortability";
import ApproveDocuments from "../ApproveDocuments/ApproveDocuments";
import ListaFiliais from "./ListaFiliais";
import ListaContratos from "./ListaContratos";
import MaxViewHeightContainer from "../Container/MaxViewHeightContainer";
import BlockedBadge from "../Contrato/BlockedBadge";
import ExpiredBadge from "../Contrato/ExpiredBadge";
import { SITUACAO } from "../../types/SituacaoDocumento";
import { isExpired } from "../../helpers/contrato";

const swal = withReactContent(MySwal);

const DateColumn = ({ value, ...props }) => (
  <div {...props}>
    {value ? moment(value).format("DD/MM/YYYY") : "--/--/----"}
  </div>
);

class GestaoDocumentos extends Component {
  constructor() {
    super();

    this._isMounted = false;
    this.state = {
      rows: [],
      pages: null,
      loading: true,
      pageSize: null,
      page: null,
      sorted: null,
      filtered: null,

      listaFiliais: [],
      loadingFiliais: false,
      filialPesquisa: "",

      empresaPesquisa: "",
      show: {
        documentos: false,
        documentosContrato: false,
        funcionarios: {},
      },
      contrato: null,
      tipoContrato: "tomador",
      isCollapseOpen: false,
      isFiredCollapseOpen: true,

      pesquisaFuncionario: "",
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleFilialFilterChange = this.handleFilialFilterChange.bind(this);
    this.handleEmpresaFilterChange = this.handleEmpresaFilterChange.bind(this);
    this.handleClearFilter = this.handleClearFilter.bind(this);
    this.loadFiliais = this.loadFiliais.bind(this);
    this.handleFetch = this.handleFetch.bind(this);
    this.handlePesquisa = this.handlePesquisa.bind(this);
    this.handleLoadContratos = this.handleLoadContratos.bind(this);
    this.handlePesquisaFilial = this.handlePesquisaFilial.bind(this);
    this.handlePesquisaFuncionario = this.handlePesquisaFuncionario.bind(this);
    this.handlePesquisaFuncionarioFilter =
      this.handlePesquisaFuncionarioFilter.bind(this);
    this.setShow = this.setShow.bind(this);

    this.handleAprovaReprovaTrocaContrato =
      this.handleAprovaReprovaTrocaContrato.bind(this);
    this.handleEmployeeDismissal = this.handleEmployeeDismissal.bind(this);
    this.handleOpenJustificativa = this.handleOpenJustificativa.bind(this);
    this.handleCreateContract = this.handleCreateContract.bind(this);
    this.handleDocumentsPortability =
      this.handleDocumentsPortability.bind(this);
    this.handleAprovarDocumentosClick =
      this.handleAprovarDocumentosClick.bind(this);
    this.afterApproveDocuments = this.afterApproveDocuments.bind(this);
  }

  searchInputRef = createRef();

  getFilialFilter(filter = null) {
    if (filter) {
      return filter;
    }
    if (this.props.appData.filtros && this.props.appData.filtros.filial) {
      return this.props.appData.filtros.filial;
    }

    return this.props.usuarioLogado.filial;
  }

  getEmpresaFilter(filter = null) {
    if (filter) {
      return filter;
    }

    if (this.props.appData.filtros && this.props.appData.filtros.empresa) {
      return this.props.appData.filtros.empresa;
    }

    return this.props.dados && this.props.dados[0] ? this.props.dados[0] : null;
  }

  getContratoFilter(filter = null) {
    if (filter) {
      return filter;
    }

    if (this.props.appData.filtros && this.props.appData.filtros.contrato) {
      return this.props.appData.filtros.contrato;
    }

    return null;
  }

  async handleFetch(
    filter = {
      filial: this.getFilialFilter(),
      empresa: this.getEmpresaFilter(),
      contrato: this.getContratoFilter(),
      searchContrato: this.state.filialPesquisa,
    },
    search = "",
    reset = false
  ) {
    const idFilialFuncionario =
      this.props.filialFuncionario?.idFilialFuncionario;
    const noDataLoaded =
      !this.props.funcionarios?.length &&
      !this.props.funcionariosRemovidos?.length;
    const needRefresh =
      this.props.refresh[REFRESH_KEY.DOCUMENTOS_FUNCIONARIOS] ||
      reset ||
      noDataLoaded;

    this.props.dispatch(setAppData({ filtros: filter, documento: null }));

    const reqData = {
      filial: this.getFilialFilter(filter.filial).idFilial,
      empresa: this.getEmpresaFilter(filter.empresa)?.idFilial || null,
      contrato: this.getContratoFilter(filter.contrato)?.idContrato || null,
      search,
    };

    this.props.dispatch(
      fetchContrato({
        search: reqData.search,
        filial: reqData.filial,
        contrato: reqData.contrato,
      })
    );
    this.props.dispatch(
      fetchList({
        search: reqData.search,
        filial: reqData.filial,
        search_contrato: filter.searchContrato,
      })
    );

    this.props.dispatch(fetchDocumentosList(reqData));
    this.props.dispatch(fetchContratoList(reqData));

    const reqDataPg = { ...reqData, pageSize: 15 };
    this.props.dispatch(
      idFilialFuncionario && !needRefresh
        ? fetchDocumentosFuncionario({
            ...reqDataPg,
            filial_funcionario_id: idFilialFuncionario,
          })
        : fetchFuncionariosList(reqDataPg, needRefresh)
    );
    this.props.dispatch(
      idFilialFuncionario && !needRefresh
        ? fetchDocumentosFuncionarioRemovido({
            ...reqDataPg,
            filial_funcionario_id: idFilialFuncionario,
          })
        : fetchFuncionariosRemovidosList(reqDataPg, needRefresh)
    );

    if (needRefresh)
      this.props.dispatch(clearRefresh(REFRESH_KEY.DOCUMENTOS_FUNCIONARIOS));

    this.setState({ pesquisa: search });
  }

  handleLoadContratos(idFuncionario) {
    const empresa = this.getEmpresaFilter();
    const contrato = this.getContratoFilter();
    const contratosComEmpresa = this.getContratosEmpresa();
    if (contratosComEmpresa?.length) {
      MySwal.fire({
        showConfirmButton: true,
        confirmButtonText: "Solicitar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        title: "Selecione um contrato:",
        html: `<p>Uma solicitação de troca de contrato para este funcionário será enviada para ${
          empresa.nome
        }.</p>
          <select class="form-control" id="contrato-change">
              <option>Selecione um contrato...</option>
              ${contratosComEmpresa?.map(function (option) {
                if (
                  parseInt(contrato.idContrato) !==
                    parseInt(option.idContrato) &&
                  contrato.tipo === option.tipo
                )
                  return `<option value="${
                    option.idContrato
                  }">${option.nome.toUpperCase()}</option>`;

                return null;
              })}
          </select>`,
      }).then((result) => {
        const sel = document.getElementById("contrato-change");
        if (result && result.value)
          this.props.dispatch(
            solicitaMudancaContrato(
              idFuncionario,
              this.getContratoFilter().idContrato,
              sel.options[sel.selectedIndex].value,
              () => {
                this.props.dispatch(
                  setRefresh(REFRESH_KEY.DOCUMENTOS_FUNCIONARIOS)
                );
                this.handleFetch();
              }
            )
          );
      });
    }
  }

  handleDocumentsPortability() {
    const contrato = this.getContratoFilter();
    const contratosComEmpresa = this.getContratosEmpresa();
    const filteredContracts =
      contratosComEmpresa?.filter(
        (option) =>
          parseInt(contrato.idContrato) !== parseInt(option.idContrato) &&
          contrato.tipo === option.tipo
      ) ?? [];
    if (filteredContracts.length) {
      MySwal.fire({
        showConfirmButton: true,
        confirmButtonText: "Selecionar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        title: "Selecione um contrato:",
        html: `<p>Selecione o Contrato do qual devemos buscar os Documentos para Portabilidade:</p>
               <select class="form-control" id="contrato-change">
                 <option>Selecione um contrato...</option>
                 ${filteredContracts?.map((option) => {
                   if (
                     parseInt(contrato.idContrato) !==
                       parseInt(option.idContrato) &&
                     contrato.tipo === option.tipo
                   )
                     return `<option value="${
                       option.idContrato
                     }">${option.nome.toUpperCase()}</option>`;

                   return null;
                 })}
               </select>`,
      }).then((result) => {
        const select = document.getElementById("contrato-change");
        const contract = contratosComEmpresa?.find(
          (contrato) =>
            contrato.idContrato ===
            parseInt(select.options[select.selectedIndex].value)
        );
        if (result && result.value && contract) {
          this.props.dispatch(setModalOpen("portability"));
          this.props.dispatch(setModalExtraData(contract));
        }
      });
      return;
    }

    MySwal.fire({
      type: "info",
      showConfirmButton: false,
      title: "Nenhum contrato disponivel para portabilidade de documentos.",
      toast: true,
      timer: 3000,
    });
  }

  handleFilialFilterChange(filter) {
    this.props.dispatch(setRefresh(REFRESH_KEY.DOCUMENTOS_FUNCIONARIOS));
    this.handleFetch(
      {
        filial: filter,
        empresa: null,
        contrato: null,
        searchContrato: this.state.filialPesquisa,
      },
      "",
      true
    );
  }

  handleEmpresaFilterChange(filter) {
    this.props.dispatch(setRefresh(REFRESH_KEY.DOCUMENTOS_FUNCIONARIOS));
    this.handleFetch(
      {
        empresa: filter,
        contrato: null,
        filial: this.getFilialFilter(),
        searchContrato: this.state.filialPesquisa,
      },
      "",
      true
    );
  }

  handleContratoFilterChange(filter) {
    this.handleFetch(
      {
        empresa: this.getEmpresaFilter(),
        contrato: filter,
        filial: this.getFilialFilter(),
        searchContrato: this.state.filialPesquisa,
      },
      "",
      true
    );
  }

  handleClearFilter() {
    this.setState(
      (state) => {
        this.searchInputRef.current.value = "";
        return { ...state, filialPesquisa: "" };
      },
      () => {
        this.loadFiliais();
        this.handleFetch({
          empresa: null,
          contrato: null,
          filial: this.getFilialFilter(),
          searchContrato: null,
        });
      }
    );
  }

  debouncePesquisaFilial = debounce(
    (text) =>
      this.setState(
        {
          filialPesquisa: text,
          loadingFiliais: true,
        },
        () => {
          this.loadFiliais();
          this.handleFetch();
        }
      ),
    500
  );

  handlePesquisaFilial(e) {
    this.debouncePesquisaFilial(e.target.value);
  }

  handleEmpresaPesquisaChange(event) {
    let pesquisa = event.target.value;
    this.setState(
      {
        empresaPesquisa: pesquisa,
        loadingEmpresas: true,
      },
      () => {
        this.props.dispatch(
          fetchList({
            s: pesquisa,
          })
        );
      }
    );
  }

  handlePesquisa(evt) {
    let pesquisa = evt.target.value;
    this.setState(
      {
        pesquisa,
        ultimaPesquisa: moment(),
      },
      () => {
        setTimeout(() => {
          var a = this.state.ultimaPesquisa;
          var b = moment();

          if (b.diff(a, "seconds") > 0) {
            let req = {};
            if (this.state.filialFilter) {
              req.filial = this.state.filialFilter;
            }
            req.s = pesquisa;
            this.handleFetch(undefined, pesquisa);

            setTimeout(() => {
              if (document.getElementById("pesquisa-documentos"))
                document.getElementById("pesquisa-documentos").focus();
            }, 1000);
          }
        }, 1000);
      }
    );
  }

  debouncePesquisaFuncionario = debounce(
    (text) => this.setState({ pesquisaFuncionario: text }),
    500
  );

  handlePesquisaFuncionario(e) {
    this.debouncePesquisaFuncionario(e.target.value);
  }

  loadFiliais() {
    loadFiliaisDocumentosOptions(this.state.filialPesquisa)
      .then((data) => {
        if (this._isMounted) {
          this.setState({
            listaFiliais: data,
            loadingFiliais: false,
          });
        }
      })
      .catch((err) => {
        if (this._isMounted) {
          this.setState({
            listaFiliais: [],
            loadingFiliais: false,
          });
        }
      });
  }

  handleEmployeeDismissal(row) {
    swal.fire({
      title: "",
      type: "warning",
      html: (
        <DismissDocumentModal
          onConfirm={(justificativa) => {
            const contrato =
              row.contrato?.idContrato ||
              this.getContratoFilter()?.idContrato ||
              undefined;
            const values = {
              documento: row.original.idDocumento,
              funcionario: row.funcionario.idFuncionario,
              filialFuncionario: row.funcionario.idFilialFuncionario,
              filial: this.getFilialFilter().idFilial,
              contrato,
              justificativa,
            };

            this.props.dispatch(
              dismissDocument(values, () => {
                this.props.dispatch(
                  setFilialFuncionarioId(row.funcionario.idFilialFuncionario)
                );
                this.handleFetch();
                this.props.dispatch(
                  setFuncionarioId({
                    id: row.funcionario.idFuncionario,
                    fired: !!row.funcionario?.dataDemissao,
                  })
                );
              })
            );
            MySwal.clickConfirm();
          }}
          onCancel={MySwal.clickCancel}
        />
      ),
      showCancelButton: false,
      showConfirmButton: false,
    });
  }

  handleOpenJustificativa(props, funcionario = null) {
    const contratoFilter = this.getContratoFilter();
    const isTomador = contratoFilter && contratoFilter.tipo === "tomador";
    const isRecusado = props.original.status === SITUACAO.RECUSADO;

    MySwal.fire({
      title: "",
      type: isRecusado ? "warning" : "info",
      html: `
        <h5 style="color:${isRecusado ? COLOR_DANGER : COLOR_INFO}">
          ${isRecusado ? "Recusado" : "Ag. Aprovação"}
        </h5>
        <b>Justificativa:</b>
        <br>
        ${props.original?.justificativa
          .split("\n")
          .map((linha) => linha + "<br/>")}
      `,
      showCancelButton: true,
      confirmButtonText: isTomador ? "Ver documento" : "Enviar novamente",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.handleOpen(
          props.original,
          funcionario || null,
          funcionario ? null : contratoFilter
        );
      }
    });
  }

  setShow(show) {
    this.setState({ show });
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadFiliais();

    const contratoFilter = this.getContratoFilter();

    this.handleFetch({
      filial: this.getFilialFilter(),
      empresa: this.getEmpresaFilter(),
      contrato:
        contratoFilter?.status === STATUS.AGUARDANDO_APROVACAO
          ? null
          : contratoFilter,
    });

    if (contratoFilter?.status === STATUS.AGUARDANDO_APROVACAO)
      this.props.dispatch(setAppData({ contrato: null }));

    if (this.props.appData.filtros && this.props.appData.filtros.pesquisa) {
      this.setState(
        {
          pesquisa: this.props.appData.filtros.pesquisa,
        },
        () => {
          let req = {};
          if (this.state.filialFilter) {
            req.filial = this.state.filialFilter;
          }
          req.s = this.props.appData.filtros.pesquisa;
          this.handleFetch(undefined, this.props.appData.filtros.pesquisa);

          setTimeout(() => {
            if (document.getElementById("pesquisa-documentos"))
              document.getElementById("pesquisa-documentos").focus();
          }, 1000);
        }
      );
    }
  }

  componentDidUpdate(nextProps) {
    if (
      this.props.appData.filtros &&
      this.props.appData.filtros.pesquisa &&
      this.props.appData.filtros.pesquisa !== this.state.pesquisa
    ) {
      this.setState({
        pesquisa: this.props.appData.filtros.pesquisa,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleCollapse() {
    this.setState({ isCollapseOpen: !this.state.isCollapseOpen });
  }

  handleFiredCollapse() {
    this.setState({ isFiredCollapseOpen: !this.state.isFiredCollapseOpen });
  }

  setDocumentContext(documento, funcionario = null, contrato = null) {
    let filialFilter = this.getFilialFilter();

    let contratoFilter = this.getContratoFilter();
    if (!contrato) {
      [contrato] = this.getContratosEmpresa().filter(
        (val) => val.idContrato === contratoFilter.idContrato
      );
    }

    if (contratoFilter?.tipo === "tomador" && contrato === null) {
      contrato = contratoFilter;
    }

    this.props.dispatch(
      setAppData({
        filial: filialFilter,
        funcionario: funcionario,
        documento: documento,
        contrato: contrato,
      })
    );
  }

  handleOpenUpload(tipo, documento, funcionario = null, contrato = null) {
    if (contrato?.status === STATUS.INATIVO && contrato?.tipo !== "tomador") {
      MySwal.fire({
        type: "warning",
        title: "CONTRATO BLOQUEADO PELO TOMADOR",
        html: "Caso necessite fazer upload de algum documento, solicitar a liberação do contrato",
      });
      return;
    }

    this.setState({ tipoDocumentoUpload: tipo });
    this.setDocumentContext(documento, funcionario, contrato);
    this.props.dispatch(
      fetchEnvio({
        filial: this.props.appData.filial,
        contrato: contrato,
        funcionario: funcionario,
        documento: documento,
      })
    );

    if (funcionario) {
      setFilialFuncionarioId(funcionario.idFilialFuncionario);
    }
    this.props.dispatch(setModalOpen("upload"));
  }

  handleOpen(documento, funcionario = null, contrato = null, comments = false) {
    this.setDocumentContext(documento, funcionario, contrato);

    if (this.getContratoFilter()?.tipo === "tomador") {
      let url = "/gestao-documentos/avaliacao/";
      if (comments) {
        url += "#comments";
      }
      this.props.history.push(url);
      return;
    }

    let url = "/documentos/envios/";
    if (comments) {
      url += "#comments";
    }
    this.props.history.push(url);
  }

  handleAprovaReprovaTrocaContrato = (funcionario) => {
    if (
      this.props.permissoes.administradorEmpresa !== 1 &&
      this.props.permissoes.administradorFilial !== 1
    ) {
      MySwal.fire({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 3000,
        type: "error",
        title:
          "É necessário ser administrador da filial para aprovar / rejeitar a troca",
      });
      return;
    }
    if (!funcionario.possoAprovar) {
      MySwal.fire({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 3000,
        type: "warning",
        title:
          "Sua empresa deve aguardar a aprovação / rejeição da troca pela outra empresa envolvida",
      });
      return;
    }
    MySwal.fire({
      title: "Troca de Contrato",
      type: "warning",
      html:
        "Deseja aceitar a troca do funcionário <b>" +
        funcionario.nome.toUpperCase() +
        "</b> para o contrato <b>" +
        funcionario.alterarContratoTitulo.toUpperCase() +
        "</b>? <br>Essa ação não pode ser revertida",
      showCancelButton: true,
      confirmButtonText: "Sim, trocar",
      cancelButtonText: "Não, recusar",
      reverseButtons: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.value) {
        this.props.dispatch(
          aprovaMudancaContrato(
            funcionario.idFuncionario,
            this.getContratoFilter().idContrato,
            funcionario.alterarContrato,
            this.handleFetch
          )
        );
      } else {
        this.props.dispatch(
          rejeitaMudancaContrato(
            funcionario.idFuncionario,
            this.getContratoFilter().idContrato,
            funcionario.alterarContrato,
            this.handleFetch
          )
        );
      }
    });
  };

  handlePesquisaFuncionarioFilter(funcionario) {
    if (funcionario.statusContratoFuncionario === DEMITIDO) return false;

    const pesquisa = new RegExp(this.state.pesquisaFuncionario, "gi");
    return pesquisa
      ? funcionario?.cargo.search(pesquisa) >= 0 ||
          funcionario?.nome.search(pesquisa) >= 0
      : true;
  }

  getContratosEmpresa() {
    let empresaFilter = this.getEmpresaFilter();

    for (let i = 0; i < this.props.dados?.length; i++) {
      if (
        empresaFilter &&
        this.props.dados[i].idFilial === empresaFilter.idFilial
      ) {
        return this.props.dados[i].contratos;
      }
    }
    return [];
  }

  handleBulkDownload(idContrato) {
    withReactContent(MySwal).fire({
      title: "BAIXAR DOCUMENTOS CONTRATO",
      html: (
        <BaixarDocumentosModal
          onSubmit={(selected) => {
            const onlyApproved = selected === "approved-documents";

            bulkDownload({
              dispatch: this.props.dispatch,
              idContrato,
              onlyApproved,
            });
            MySwal.clickConfirm();
          }}
          onCancel={MySwal.clickCancel}
        />
      ),
      showCancelButton: false,
      showConfirmButton: false,
    });
  }

  getContratoSelecionado() {
    return this.getContratosEmpresa()
      .filter(
        (c) => c.idContrato === this.props.appData?.filtros?.contrato.idContrato
      )
      .pop();
  }

  handleCreateContract() {
    if (!this.props?.usuarioLogado?.planoCriarContratos) {
      return swal.fire({
        title: "",
        type: "question",
        html: <AlertSemPlano />,
        showCancelButton: false,
      });
    }

    this.props.dispatch(clearData());
    this.props.dispatch(setModalOpen("new"));
  }

  handleAprovarDocumentosClick(funcionario, documentos) {
    this.props.dispatch(setModalOpen("approveDocuments"));
    this.props.dispatch(setModalExtraData({ funcionario, documentos }));
  }

  afterApproveDocuments() {
    this.props.dispatch(setRefresh(REFRESH_KEY.DOCUMENTOS_FUNCIONARIOS));
    this.handleFetch();
  }

  render() {
    if (this.props.error && this.props.modal === "") {
      return <Error erro={this.props.error}></Error>;
    }

    let filialFilter = this.getFilialFilter();
    let empresaFilter = this.getEmpresaFilter();
    let contratoFilter = this.getContratoFilter();

    const openDocument = (documento, comments = false) =>
      this.handleOpen(documento, null, contratoFilter, comments);

    let openDocumentEmployee = (
      documento,
      funcionario,
      contrato = null,
      comments = false
    ) =>
      this.handleOpen(
        documento,
        funcionario,
        contrato || contratoFilter,
        comments
      );

    openDocumentEmployee = openDocumentEmployee.bind(this);

    const invalidos_len = this.props.documentos?.reduce((sum, val) => {
      if (val.status < 1 || val.status === 2) {
        return sum + 1;
      } else {
        return sum;
      }
    }, 0);

    const invalidos_contrato_len = this.props.documentosContrato.reduce(
      (sum, val) => {
        if (val.status < 1 || val.status === 2) {
          return sum + 1;
        }
        return sum;
      },
      0
    );

    const contratos = empresaFilter ? this.getContratosEmpresa() : [];

    const maisContratos = empresaFilter
      ? this.getContratosEmpresa().map((val, idx) => {
          let a = moment(val.dataInicio.date);
          let b = moment(val.dataFim.date);

          let diffDuration = moment.duration(b.diff(moment().startOf("day")));

          let anosRestantes = diffDuration.years();
          let mesesRestantes = diffDuration.months();
          let diasRestantes = diffDuration.days();

          const color =
            val.porcentagem < 100
              ? val.invalidos
                ? COLOR_DANGER
                : COLOR_WARNING
              : COLOR_GREEN;
          const left =
            val.porcentagem < 5
              ? 5
              : val.porcentagem > 95
              ? 95
              : val.porcentagem;

          // MODELO PARA EXIBIÇÃO DE CONTRATO
          const rowMaisContrato = (
            <div
              key={idx}
              className={
                "row cursor-pointer m-0 justify-content-center " +
                (val.invalidos === 0 &&
                val.pendentes === 0 &&
                val.aguardando === 0
                  ? "success"
                  : "warning")
              }
              onClick={() => {
                this.handleContratoFilterChange(val);
              }}
            >
              <div className="col-12 col-md-auto d-flex justify-content-center">
                {(val.invalidos > 0 ||
                  val.pendentes > 0 ||
                  val.aguardando > 0) && (
                  <div
                    className="d-flex flex-column align-items-center justify-content-center"
                    style={{
                      color: val.invalidos > 0 ? COLOR_DANGER : COLOR_WARNING,
                    }}
                  >
                    <FontAwesomeIcon icon="exclamation-triangle" />
                    Irregular
                    <br />
                    {val.tipo === "terceiro" && (
                      <span
                        style={{
                          color: "#35bae9",
                          fontWeight: "bold",
                        }}
                        className="small"
                      >
                        <FontAwesomeIcon icon="id-card-alt" className="mr-2" />
                        Sou terceiro
                      </span>
                    )}
                    {val.tipo === "tomador" && (
                      <span
                        style={{
                          color: "#35bae9",
                          fontWeight: "bold",
                        }}
                        className="small"
                      >
                        <FontAwesomeIcon icon="building" className="mr-2" />
                        Sou tomador
                      </span>
                    )}
                  </div>
                )}
                {val.invalidos === 0 &&
                  val.pendentes === 0 &&
                  val.aguardando === 0 && (
                    <div
                      className="d-flex flex-column align-items-center justify-content-center"
                      style={{ color: COLOR_GREEN }}
                    >
                      <FontAwesomeIcon icon="check" />
                      Regular
                      <br />
                      {val.tipo === "terceiro" && (
                        <span
                          style={{
                            color: "#35bae9",
                            fontWeight: "bold",
                          }}
                          className="small"
                        >
                          <FontAwesomeIcon
                            icon="id-card-alt"
                            className="mr-2"
                          />
                          Sou terceiro
                        </span>
                      )}
                      {val.tipo === "tomador" && (
                        <span
                          style={{
                            color: "#35bae9",
                            fontWeight: "bold",
                          }}
                          className="small"
                        >
                          <FontAwesomeIcon icon="building" className="mr-2" />
                          Sou tomador
                        </span>
                      )}
                    </div>
                  )}
              </div>
              <div className="col-auto d-flex flex-column text-center text-md-left">
                <span
                  style={{
                    color: COLOR_GRAY2,
                    fontSize: "14px",
                  }}
                >
                  Nome: <b className="d-md-none">{val.nome}</b>
                </span>
                <span
                  style={{
                    color: COLOR_GRAY2,
                    fontSize: "14px",
                  }}
                >
                  Data inicial - final:{" "}
                  <b className="d-md-none">
                    {a.format("DD/MM/YYYY")} - {b.format("DD/MM/YYYY")}
                  </b>
                </span>
                <span
                  style={{
                    color: COLOR_GRAY2,
                    fontSize: "14px",
                  }}
                >
                  Comentários:{" "}
                  <b className="d-md-none">
                    {val.comentarios}
                    <button
                      className="a ml-4"
                      onClick={() => {
                        this.setState({
                          contrato: val.idContrato,
                          tipoContrato:
                            val.tipo === "terceiro" ? "tomador" : "terceiro",
                        });
                        this.props.dispatch(setModalOpen("comments"));
                      }}
                      title="Ver"
                    >
                      <FontAwesomeIcon icon="envelope" />
                    </button>
                  </b>
                </span>
                <div className="d-flex">
                  <button
                    type="button"
                    onClick={() => {
                      this.props.dispatch(setModalOpen("edit"));
                      this.props.dispatch(setModalId(val.idContrato));
                    }}
                    style={{
                      textDecoration: "underline",
                      fontSize: "14px",
                    }}
                    className="mt-2 mr-3 font-weight-600"
                  >
                    EDITAR CONTRATO
                  </button>
                  <button
                    type="button"
                    onClick={() => this.handleBulkDownload(val.idContrato)}
                    style={{
                      textDecoration: "underline",
                      fontSize: "14px",
                    }}
                    className="mt-2 font-weight-600"
                  >
                    BAIXAR DOCUMENTOS
                  </button>
                </div>
              </div>
              <div className="d-none col-12 col-md-5 d-md-flex flex-column text-center text-md-left">
                <span style={{ fontSize: "14px" }}>{val.nome}</span>
                <span style={{ fontSize: "14px" }}>
                  {moment(val.dataInicio.date).format("DD/MM/YYYY")}
                  &nbsp;-&nbsp;
                  {moment(val.dataFim.date).format("DD/MM/YYYY")}
                  &nbsp;
                  <span
                    style={{
                      color: "#666666",
                      fontWeight: "400",
                    }}
                  >
                    ({anosRestantes > 1 && anosRestantes + " anos, "}
                    {anosRestantes === 1 && "1 ano, "}
                    {mesesRestantes > 1 && mesesRestantes + " meses e "}
                    {mesesRestantes === 1 && "1 mês e "}
                    {diasRestantes > 1 && diasRestantes + " dias restantes"}
                    {diasRestantes === 1 && "1 dia restante "})
                  </span>
                </span>
                <span style={{ fontSize: "14px" }}>
                  {val.comentarios}
                  <button
                    className="a ml-4"
                    onClick={() => {
                      this.setState({
                        contrato: val.idContrato,
                        tipoContrato:
                          val.tipo === "terceiro" ? "tomador" : "terceiro",
                      });
                      this.props.dispatch(setModalOpen("comments"));
                    }}
                    title="Ver"
                  >
                    <FontAwesomeIcon icon="envelope" />
                  </button>
                </span>
              </div>
              <div className="col-12 col-md">
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Popover
                      placement="bottom"
                      id="popover-basic"
                      title={
                        parseInt(val.porcentagem) < 100
                          ? "Como melhorar?"
                          : "Tudo certo"
                      }
                    >
                      {parseInt(val.porcentagem) < 100 && (
                        <div>
                          {val.pendentes > 0 && (
                            <div
                              style={{
                                color: COLOR_WARNING,
                              }}
                            >
                              <FontAwesomeIcon icon="cloud-upload-alt" />{" "}
                              <b>Documento(s) Pendente(s)</b>: Para solucionar
                              este problema devem ser enviadas versões, que irão
                              para a análise dos contratantes
                            </div>
                          )}
                          {val.aguardando > 0 && (
                            <div
                              style={{
                                color: COLOR_INFO,
                              }}
                            >
                              <FontAwesomeIcon icon="hourglass-half" />{" "}
                              <b>Documento(s) Aguardando Avaliação</b>: Para
                              solucionar este problema os documentos devem ser
                              avaliados por todos os contratantes
                            </div>
                          )}
                          {val.invalidos > 0 && (
                            <div
                              style={{
                                color: COLOR_DANGER,
                              }}
                            >
                              <FontAwesomeIcon icon="ban" />{" "}
                              <b>Documento(s) Inválido(s)</b>: Caso o documento
                              tenha sido recusado, é possível reenviá-lo ao
                              contrato que o fez, ou para todos os contratos,
                              caso o documento tenha expirado deve-se ser
                              enviado a todos os contratos
                            </div>
                          )}
                        </div>
                      )}
                      {parseInt(val.porcentagem) >= 100 && (
                        <div style={{ color: COLOR_GREEN }}>
                          <FontAwesomeIcon icon="check" /> Nenhuma pendência
                          neste contrato
                        </div>
                      )}
                    </Popover>
                  }
                >
                  <div className="progress mt-4">
                    <div
                      className="progress-bar"
                      style={{
                        background: color,
                        width: parseInt(val.porcentagem) + "%",
                      }}
                      role="progressbar"
                      aria-valuenow={parseInt(val.porcentagem)}
                      aria-valuemin="0"
                      aria-valuemax="100"
                      id="progressbar"
                    >
                      <span
                        className="progress-indicator"
                        style={{
                          backgroundColor: color,
                          borderColor: color,
                          left: left + "%",
                        }}
                      >
                        {parseInt(val.porcentagem)}%
                      </span>
                    </div>
                  </div>
                </OverlayTrigger>
                <div
                  className="w-100 text-center font-weight-500 pt-2"
                  style={{ color: color, fontSize: "15px" }}
                >
                  {val.invalidos > 0 && (
                    <span className="d-block w-100">
                      HÁ <b>{val.invalidos}</b> IRREGULARIDADE(S) NO CONTRATO
                    </span>
                  )}
                  {val.pendentes + val.aguardando > 0 && (
                    <span className="d-block w-100">
                      HÁ <b>{val.pendentes + val.aguardando}</b> PENDÊNCIA(S) NO
                      CONTRATO
                    </span>
                  )}
                </div>
              </div>
            </div>
          );
          // FIM MODELO PARA EXIBIÇÃO DE CONTRATO

          if (contratoFilter && val.idContrato !== contratoFilter.idContrato) {
            return rowMaisContrato;
          }

          return null;
        })
      : "";

    let vencido = false;
    if (this.props.appData?.filtros?.contrato) {
      const contrato = this.getContratoSelecionado() || contratoFilter;
      vencido = contrato && isExpired(contrato)
    }

    return (
      <div>
        <div className="row justify-content-center mx-0">
          <div className="col-12 px-0">
            <div className="card tertium-card">
              <div className="card-header py-0">
                <div className="row m-0">
                  <div
                    className="col-12 col-lg-3 d-flex align-items-center justify-content-center py-4 py-lg-0"
                    style={{
                      borderRadius: "0",
                      boxShadow: "none",
                      borderTopLeftRadius: "calc(.25rem - 1px)",
                      backgroundColor: "#f15923",
                      borderBottom: "1px solid #cd4c1e",
                    }}
                  >
                    <input
                      ref={this.searchInputRef}
                      className="transparent-input"
                      type="text"
                      title="Buscar por filial / título do contrato / empresa terceira"
                      placeholder="Buscar por filial / título do contrato / empresa terceira"
                      defaultValue={this.state.filialPesquisa}
                      onChange={this.handlePesquisaFilial}
                    />
                    <img
                      src="/images/search.png"
                      alt="Pesquisar"
                      title="Pesquisar"
                      style={{
                        maxWidth: "18px",
                      }}
                    />
                  </div>

                  <div
                    className="col-12 col-lg-9 py-3 pl-2 pl-lg-5 d-flex justify-content-between align-items-center"
                    style={{ paddingRight: "120px" }}
                  >
                    <div className="d-inline-block">
                      <span
                        className="cursor-pointer"
                        onClick={() => {
                          this.handleClearFilter();
                        }}
                      >
                        {filialFilter.nome ? filialFilter.nome : "Documentos"}
                      </span>
                      {empresaFilter && (
                        <div
                          className="d-inline-block cursor-pointer"
                          onClick={() => {
                            this.handleEmpresaFilterChange(
                              this.getEmpresaFilter()
                            );
                          }}
                        >
                          &nbsp;
                          <FontAwesomeIcon
                            icon="exchange-alt"
                            style={{
                              color: COLOR_PRIMARY,
                            }}
                          />{" "}
                          {empresaFilter.nome}
                        </div>
                      )}
                      {contratoFilter && (
                        <div className="d-flex align-items-center w-100">
                          &nbsp;
                          <FontAwesomeIcon
                            icon="signature"
                            style={{
                              color: COLOR_ORANGE,
                            }}
                          />
                          <span className="text-muted">
                            &nbsp;
                            {contratoFilter.nome}
                            &nbsp;
                            {contratoFilter.status === STATUS.INATIVO && (
                              <BlockedBadge />
                            )}
                            {contratoFilter.status !== STATUS.INATIVO &&
                              vencido && <ExpiredBadge />}
                          </span>
                        </div>
                      )}
                    </div>

                    {contratoFilter && (
                      <div
                        className="d-block text-center"
                        style={{
                          lineHeight: 1,
                          paddingRight: "20px",
                        }}
                      >
                        {contratoFilter.tipo === "terceiro" && (
                          <span
                            style={{
                              color: "#35bae9",
                              fontWeight: "bold",
                            }}
                            className="small"
                          >
                            <FontAwesomeIcon
                              icon="id-card-alt"
                              className="mr-2"
                            />
                            Sou terceiro
                          </span>
                        )}
                        {contratoFilter.tipo === "tomador" && (
                          <span
                            style={{
                              color: "#35bae9",
                              fontWeight: "bold",
                            }}
                            className="small"
                          >
                            <FontAwesomeIcon icon="building" className="mr-2" />
                            Sou tomador
                          </span>
                        )}
                      </div>
                    )}

                    {(empresaFilter || contratoFilter) && (
                      <div
                        style={{
                          backgroundColor: COLOR_GRAY2,
                          right: 0,
                          position: "absolute",
                          color: "white",
                          height: "100%",
                          top: 0,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "10px",
                        }}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <button
                          type="button"
                          style={{
                            color: "white",
                            boxShadow: "none",
                            lineHeight: 1,
                          }}
                          title="Limpar filtros"
                          onClick={() => this.handleClearFilter()}
                        >
                          <FontAwesomeIcon icon="times" /> <br />
                          Limpar filtros
                        </button>
                      </div>
                    )}

                    {!contratoFilter && (
                      <ButtonPrimary
                        title="Criar novo contrato"
                        onClick={this.handleCreateContract}
                        style={{
                          position: "absolute",
                          right: "0",
                          top: "0",
                          height: "100%",
                          borderRadius: "0",
                          boxShadow: "none",
                          borderTopRightRadius: "calc(.25rem - 1px)",
                          backgroundColor: "#f15923",
                          borderBottom: "1px solid #cd4c1e",
                          marginBottom: "-1px",
                        }}
                      >
                        <span className="d-none d-md-inline-block px-5 mx-3">
                          Novo contrato
                        </span>
                        <img
                          src={"/images/plus.png"}
                          alt="Criar novo contrato"
                          title="Criar novo contrato"
                          className="img-fluid ml-0 plus-img"
                          style={{
                            maxWidth: "25px",
                            transform: "translateY(-3px)",
                            position: "absolute",
                            right: "20px",
                          }}
                        />
                      </ButtonPrimary>
                    )}
                  </div>
                </div>
              </div>
              <div className="card-body py-0 main-ref" ref={this.mainRef}>
                <div className="row mx-0 justify-content-center">
                  <MaxViewHeightContainer
                    className="filiais-container col-12 col-lg-3 py-3 custom-list overflow-on-md"
                    style={{
                      backgroundColor: COLOR_VERY_LIGHT,
                      fontSize: "14px",
                    }}
                  >
                    {this.state.loadingFiliais && (
                      <div className="col-12">Carregando...</div>
                    )}
                    {!this.state.loadingFiliais && (
                      <ListaFiliais
                        idFilialAtiva={filialFilter.idFilial}
                        filtroPesquisa={this.state.filtroPesquisa}
                        filiais={this.state.listaFiliais}
                        loadingEmpresas={this.state.loadingEmpresas}
                        idFilialEmpresa={empresaFilter?.idFilial}
                        empresas={this.props.dados}
                        handleFilialFilterChange={this.handleFilialFilterChange}
                        handleEmpresaFilterChange={
                          this.handleEmpresaFilterChange
                        }
                      />
                    )}
                    {(empresaFilter || contratoFilter) && (
                      <button
                        type="button"
                        style={{
                          color: COLOR_DARK2,
                          boxShadow: "none",
                        }}
                        className="mx-auto d-block mt-4"
                        title="Limpar filtros"
                        onClick={() => {
                          this.handleClearFilter();
                        }}
                      >
                        <FontAwesomeIcon icon="times" /> Limpar filtros
                      </button>
                    )}
                  </MaxViewHeightContainer>

                  <MaxViewHeightContainer className="contratos-container col-12 col-lg-9 px-2 px-lg-5 py-3 pb-5">
                    {this.props?.loadingDocumentosContrato ||
                    this.props?.loading ||
                    this.props.loadingEmpresas ? (
                      <LoadingImage />
                    ) : (
                      <>
                        <div className="input-group mb-4">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              style={{
                                height: "38px",
                                backgroundColor: COLOR_PRIMARY,
                                borderColor: COLOR_PRIMARY,
                                color: "white",
                              }}
                              id="pesquisa-documentos-addon"
                            >
                              <FontAwesomeIcon icon="search" />
                            </span>
                          </div>
                          <input
                            tabIndex={-1}
                            id="pesquisa-documentos"
                            className="form-control"
                            placeholder={"Pesquisa por documentos"}
                            value={this.state.pesquisa}
                            onChange={this.handlePesquisa}
                          ></input>
                        </div>
                        {empresaFilter && (
                          <div className="mt-2 mb-3">
                            <h3
                              className="font-weight-400 py-2 mb-3"
                              style={{
                                color: COLOR_DARK2,
                                borderBottom: "2px solid #c8c8c8",
                              }}
                            >
                              Contratos
                              <span
                                style={{
                                  backgroundColor: COLOR_DARK2,
                                  borderRadius: "20px",
                                  padding: "8px 20px",
                                  color: "white",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                }}
                                onClick={() => {
                                  this.props.dispatch(clearData());
                                  this.props.dispatch(setModalOpen("new"));
                                }}
                                className="small float-right mt-1 cursor-pointer"
                              >
                                NOVO CONTRATO COM ESTA EMPRESA
                              </span>
                            </h3>
                            <div className="lista-contratos">
                              <ListaContratos
                                contratos={contratos}
                                contratoFilter={contratoFilter}
                                handleContratoFilterChange={(val) =>
                                  this.handleContratoFilterChange(val)
                                }
                                handleOpenComments={(val) => {
                                  this.setState({
                                    contrato: val.idContrato,
                                    tipoContrato:
                                      val.tipo === "terceiro"
                                        ? "tomador"
                                        : "terceiro",
                                    funcionario: null,
                                  });
                                  this.props.dispatch(setModalOpen("comments"));
                                }}
                                handleEdit={(val) => {
                                  this.props.dispatch(setModalOpen("edit"));
                                  this.props.dispatch(
                                    setModalId(val.idContrato)
                                  );
                                }}
                                handleBulkDownload={(val) =>
                                  this.handleBulkDownload(val.idContrato)
                                }
                              />
                            </div>
                            {/* ACCORDION PARA LISTAR OUTROS CONTRATOS */}
                            {contratoFilter && maisContratos?.length > 1 && (
                              <Accordion>
                                <Accordion.Toggle
                                  className="cursor-pointer"
                                  eventKey="0"
                                >
                                  <span
                                    className="font-weight-500"
                                    style={{
                                      color: "#35bae9",
                                      fontSize: "14px",
                                    }}
                                  >
                                    MOSTRAR MAIS CONTRATOS
                                  </span>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                  <div className="lista-contratos">
                                    {maisContratos}
                                  </div>
                                </Accordion.Collapse>
                              </Accordion>
                            )}
                            {/* FIM ACCORDION PARA LISTAR OUTROS CONTRATOS */}
                            {contratos?.length > 0 && !contratoFilter && (
                              <div
                                className="mt-3"
                                style={{
                                  color: COLOR_INFO,
                                  backgroundColor: COLOR_VERY_LIGHT_BLUE,
                                  padding: "10px 15px",
                                  fontSize: "13px",
                                }}
                              >
                                <FontAwesomeIcon icon="lightbulb" /> Para
                                visualizar arquivos específicos de um contrato,
                                clique sobre o mesmo na tabela acima.
                              </div>
                            )}
                          </div>
                        )}
                        {!empresaFilter && (
                          <div
                            className="mt-3"
                            style={{
                              color: COLOR_INFO,
                              backgroundColor: COLOR_VERY_LIGHT_BLUE,
                              padding: "10px 15px",
                              fontSize: "13px",
                            }}
                          >
                            <FontAwesomeIcon icon="lightbulb" /> Para visualizar
                            os contratos, selecione a empresa correspondente.
                          </div>
                        )}
                        {contratoFilter && (
                          <>
                            <div className="mb-5">
                              <div className="d-flex align-items-center gap-1">
                                <h3
                                  className="font-weight-400 flex-grow-1"
                                  style={{
                                    color: COLOR_DARK2,
                                  }}
                                >
                                  Documentos do Contrato
                                </h3>
                                {contratoFilter.tipo === "tomador" &&
                                  !!this.props.documentosContrato?.length && (
                                    <ButtonGray
                                      onClick={() =>
                                        this.handleDocumentsPortability()
                                      }
                                      type="button"
                                      className="py-1 mb-2"
                                    >
                                      <small>
                                        <FontAwesomeIcon icon="copy" /> PORTAR
                                        DE OUTRO CONTRATO
                                      </small>
                                    </ButtonGray>
                                  )}
                              </div>
                              <ReactTable
                                columns={[
                                  {
                                    Header: "Nome",
                                    accessor: "nome",
                                    headerClassName: "",
                                    filterable: false,
                                    className: "font-weight-600",
                                    Cell: (props) => {
                                      return (
                                        <div
                                          className="d-flex align-items-center gap-1"
                                          style={{
                                            color: props.original.dispensado
                                              ? COLOR_GRAY2
                                              : "initial",
                                          }}
                                          onDoubleClick={() =>
                                            openDocument(props.original)
                                          }
                                          title={
                                            props.original.dispensado
                                              ? "Documento dispensado"
                                              : null
                                          }
                                        >
                                          {!!props.original.dispensado && (
                                            <img
                                              src="/images/documento_dispensado.png"
                                              alt="Documento dispensado"
                                              style={{
                                                height: "1.8em",
                                                opacity: 0.75,
                                                mixBlendMode: "multiply",
                                                userSelect: "none",
                                                float: "left",
                                              }}
                                            />
                                          )}
                                          {props.value}
                                        </div>
                                      );
                                    },
                                  },
                                  {
                                    Header: "Data Inicial",
                                    accessor: "dataInicio.date",
                                    filterable: false,
                                    maxWidth: 120,
                                    Cell: (props) => (
                                      <DateColumn
                                        value={props.value}
                                        onDoubleClick={() =>
                                          openDocument(props.original)
                                        }
                                      />
                                    ),
                                  },
                                  {
                                    Header: "Data Final",
                                    accessor: "dataFim.date",
                                    filterable: false,
                                    maxWidth: 120,
                                    Cell: (props) => (
                                      <DateColumn
                                        value={props.value}
                                        onDoubleClick={() =>
                                          openDocument(props.original)
                                        }
                                      />
                                    ),
                                  },
                                  {
                                    Header: "Situação",
                                    accessor: "status",
                                    filterable: false,
                                    maxWidth: 150,
                                    Cell: (props) => {
                                      switch (props.value) {
                                        case -2: // Recusado
                                          return (
                                            <div
                                              className={
                                                props.original?.justificativa
                                                  ? "cursor-pointer"
                                                  : ""
                                              }
                                              onDoubleClick={() =>
                                                openDocument(props.original)
                                              }
                                              style={{
                                                color: COLOR_DANGER,
                                              }}
                                              onClick={() => {
                                                if (
                                                  props.original?.justificativa
                                                ) {
                                                  this.handleOpenJustificativa(
                                                    props
                                                  );
                                                }
                                              }}
                                            >
                                              <FontAwesomeIcon icon="ban" />{" "}
                                              Recusado{" "}
                                              {props.original
                                                ?.justificativa && (
                                                <FontAwesomeIcon icon="question-circle" />
                                              )}
                                            </div>
                                          );
                                        case -1: // Expirado
                                          return (
                                            <div
                                              onDoubleClick={() =>
                                                openDocument(props.original)
                                              }
                                              style={{
                                                color: COLOR_DANGER,
                                              }}
                                            >
                                              <FontAwesomeIcon icon="exclamation-circle" />{" "}
                                              Expirado
                                            </div>
                                          );
                                        case 0: // A fazer upload
                                          return (
                                            <div
                                              onDoubleClick={() =>
                                                openDocument(props.original)
                                              }
                                              style={{
                                                color: COLOR_WARNING,
                                              }}
                                            >
                                              <FontAwesomeIcon icon="cloud-upload-alt" />{" "}
                                              Pendente
                                            </div>
                                          );
                                        case 1: // Próximo da expiração
                                          return (
                                            <div
                                              onDoubleClick={() =>
                                                openDocument(props.original)
                                              }
                                              style={{
                                                color: COLOR_INFO,
                                              }}
                                            >
                                              <FontAwesomeIcon icon="clock" />{" "}
                                              Expirando
                                            </div>
                                          );
                                        case 2: // Aguardando aprovação
                                          return (
                                            <div
                                              onDoubleClick={() =>
                                                openDocument(props.original)
                                              }
                                              style={{
                                                color: COLOR_INFO,
                                              }}
                                            >
                                              <FontAwesomeIcon icon="hourglass-half" />{" "}
                                              Ag. Aprovação
                                            </div>
                                          );
                                        case 3: // Sem pendências
                                          return (
                                            <div
                                              onDoubleClick={() =>
                                                openDocument(props.original)
                                              }
                                              style={{
                                                color: COLOR_GREEN,
                                              }}
                                            >
                                              <FontAwesomeIcon icon="check" />{" "}
                                              Regular
                                            </div>
                                          );
                                        default:
                                          return null;
                                      }
                                    },
                                  },
                                  {
                                    Header: "Comentários",
                                    accessor: "comentarios",
                                    filterable: false,
                                    maxWidth: 150,
                                    Cell: (props) => {
                                      return (
                                        <div>
                                          {props.value}
                                          <span className="d-inline-block d-md-none pl-2">
                                            Comentário(s)
                                          </span>
                                          <button
                                            className="a ml-4"
                                            onDoubleClick={() =>
                                              openDocument(props.original, true)
                                            }
                                            title="Ver"
                                          >
                                            <FontAwesomeIcon icon="envelope" />
                                          </button>
                                        </div>
                                      );
                                    },
                                  },
                                  {
                                    Header: "",
                                    filterable: false,
                                    sortable: false,
                                    className: "option-column-5",
                                    headerClassName: "option-column-5",
                                    Cell: (props) => {
                                      return (
                                        <button
                                          className="a"
                                          onClick={() =>
                                            openDocument(props.original)
                                          }
                                          title="Ver"
                                        >
                                          <FontAwesomeIcon icon="eye" />
                                        </button>
                                      );
                                    },
                                  },
                                  vencido
                                    ? null
                                    : UploadColumn({
                                        contrato: contratoFilter,
                                        onClick: (ev, props) =>
                                          this.handleOpenUpload(
                                            "CON",
                                            props.original,
                                            null,
                                            contratoFilter
                                          ),
                                      }),
                                ].filter((v) => v != null)}
                                defaultPageSize={
                                  this.state.show.documentosContrato
                                    ? this.props.documentosContrato?.length
                                    : 5
                                }
                                pageSize={
                                  this.state.show.documentosContrato
                                    ? this.props.documentosContrato?.length
                                    : 5
                                }
                                multiSort={false}
                                data={this.props.documentosContrato}
                                filterable={false}
                                resizable={false}
                                noDataText="Nenhum documento encontrado"
                                minRows={0}
                                showPagination={false}
                                showPaginationBottom={false}
                                showPageSizeOptions={false}
                                className="-highlight mb-3"
                              />
                              {this.props.documentosContrato?.length > 5 &&
                                !this.state.show.documentosContrato && (
                                  <div className="w-100">
                                    <ButtonPrimary
                                      title="Ver mais documentos"
                                      onClick={() => {
                                        let show = this.state.show;
                                        show.documentosContrato = true;
                                        this.setState({
                                          show: show,
                                        });
                                      }}
                                    >
                                      VER + DOCUMENTOS
                                    </ButtonPrimary>
                                    {invalidos_contrato_len - 5 > 0 && (
                                      <span
                                        className="ml-2 font-weight-500"
                                        style={{
                                          color: COLOR_WARNING,
                                          fontSize: "14px",
                                        }}
                                      >
                                        <FontAwesomeIcon icon="exclamation-triangle" />{" "}
                                        + {invalidos_contrato_len - 5}{" "}
                                        irregulares
                                      </span>
                                    )}
                                  </div>
                                )}
                              {this.props.documentosContrato?.length > 5 &&
                                this.state.show.documentosContrato && (
                                  <ButtonPrimary
                                    title="Ver menos documentos"
                                    onClick={() => {
                                      let show = this.state.show;
                                      show.documentosContrato = false;
                                      this.setState({
                                        show: show,
                                      });
                                    }}
                                  >
                                    VER - DOCUMENTOS
                                  </ButtonPrimary>
                                )}
                            </div>

                            <h3
                              className="font-weight-400"
                              style={{
                                color: COLOR_DARK2,
                              }}
                            >
                              {contratoFilter
                                ? "Documentos do Terceiro"
                                : "Meus Documentos"}
                            </h3>
                            <ReactTable
                              columns={[
                                {
                                  Header: "Nome",
                                  accessor: "nome",
                                  headerClassName: "",
                                  filterable: false,
                                  className: "font-weight-600",
                                  Cell: (props) => (
                                    <div
                                      onDoubleClick={() =>
                                        openDocument(props.original)
                                      }
                                    >
                                      {props.value}
                                    </div>
                                  ),
                                },
                                {
                                  Header: "Data Inicial",
                                  accessor: "dataInicio.date",
                                  filterable: false,
                                  maxWidth: 120,
                                  Cell: (props) => (
                                    <DateColumn
                                      onDoubleClick={() =>
                                        openDocument(props.original)
                                      }
                                      value={props.value}
                                    />
                                  ),
                                },
                                {
                                  Header: "Data Final",
                                  accessor: "dataFim.date",
                                  filterable: false,
                                  maxWidth: 120,
                                  Cell: (props) => (
                                    <DateColumn
                                      onDoubleClick={() =>
                                        openDocument(props.original)
                                      }
                                      value={props.value}
                                    />
                                  ),
                                },
                                {
                                  Header: "Situação",
                                  accessor: "status",
                                  filterable: false,
                                  maxWidth: 150,
                                  Cell: (props) => {
                                    switch (props.value) {
                                      case -2: // Recusado
                                        return (
                                          <div
                                            className={
                                              props.original?.justificativa
                                                ? "cursor-pointer"
                                                : ""
                                            }
                                            onDoubleClick={() =>
                                              openDocument(props.original)
                                            }
                                            style={{
                                              color: COLOR_DANGER,
                                            }}
                                            onClick={() => {
                                              if (
                                                props.original?.justificativa
                                              ) {
                                                this.handleOpenJustificativa(
                                                  props
                                                );
                                              }
                                            }}
                                          >
                                            <FontAwesomeIcon icon="ban" />{" "}
                                            Recusado{" "}
                                            {props.original?.justificativa && (
                                              <FontAwesomeIcon icon="question-circle" />
                                            )}
                                          </div>
                                        );
                                      case -1: // Expirado
                                        return (
                                          <div
                                            onDoubleClick={() =>
                                              openDocument(props.original)
                                            }
                                            style={{
                                              color: COLOR_DANGER,
                                            }}
                                          >
                                            <FontAwesomeIcon icon="exclamation-circle" />{" "}
                                            Expirado
                                          </div>
                                        );
                                      case 0: // A fazer upload
                                        return (
                                          <div
                                            onDoubleClick={() =>
                                              openDocument(props.original)
                                            }
                                            style={{
                                              color: COLOR_WARNING,
                                            }}
                                          >
                                            <FontAwesomeIcon icon="cloud-upload-alt" />{" "}
                                            Pendente
                                          </div>
                                        );
                                      case 1: // Próximo da expiração
                                        return (
                                          <div
                                            onDoubleClick={() =>
                                              openDocument(props.original)
                                            }
                                            style={{
                                              color: COLOR_INFO,
                                            }}
                                          >
                                            <FontAwesomeIcon icon="clock" />{" "}
                                            Expirando
                                          </div>
                                        );
                                      case 2: // Aguardando aprovação
                                        return (
                                          <div
                                            onDoubleClick={() =>
                                              openDocument(props.original)
                                            }
                                            style={{
                                              color: COLOR_INFO,
                                            }}
                                          >
                                            <FontAwesomeIcon icon="hourglass-half" />{" "}
                                            Ag. Aprovação
                                          </div>
                                        );
                                      case 3: // Sem pendências
                                        return (
                                          <div
                                            onDoubleClick={() =>
                                              openDocument(props.original)
                                            }
                                            style={{
                                              color: COLOR_GREEN,
                                            }}
                                          >
                                            <FontAwesomeIcon icon="check" />{" "}
                                            Regular
                                          </div>
                                        );
                                      default:
                                        return null;
                                    }
                                  },
                                },
                                {
                                  Header: "Comentários",
                                  accessor: "comentarios",
                                  filterable: false,
                                  maxWidth: 150,
                                  Cell: (props) => {
                                    return (
                                      <div>
                                        {props.value}
                                        <span className="d-inline-block d-md-none pl-2">
                                          Comentário(s)
                                        </span>
                                        <button
                                          className="a ml-4"
                                          onClick={() =>
                                            openDocument(props.original, true)
                                          }
                                          title="Ver"
                                        >
                                          <FontAwesomeIcon icon="envelope" />
                                        </button>
                                      </div>
                                    );
                                  },
                                },
                                {
                                  Header: "",
                                  filterable: false,
                                  sortable: false,
                                  className: "option-column-5",
                                  headerClassName: "option-column-5",
                                  Cell: (props) => {
                                    return (
                                      <button
                                        className="a"
                                        onClick={() =>
                                          openDocument(props.original)
                                        }
                                        title="Ver"
                                      >
                                        <FontAwesomeIcon icon="eye" />
                                      </button>
                                    );
                                  },
                                },
                                vencido
                                  ? null
                                  : UploadColumn({
                                      contrato: contratoFilter,
                                      onClick: (ev, props) =>
                                        this.handleOpenUpload(
                                          "EMP",
                                          props.original,
                                          null,
                                          contratoFilter
                                        ),
                                    }),
                              ].filter((v) => v != null)}
                              defaultPageSize={
                                this.state.show.documentos
                                  ? this.props.documentos?.length
                                  : 5
                              }
                              pageSize={
                                this.state.show.documentos
                                  ? this.props.documentos?.length
                                  : 5
                              }
                              multiSort={false}
                              data={this.props.documentos}
                              filterable={false}
                              resizable={false}
                              noDataText="Nenhum documento encontrado"
                              minRows={0}
                              showPagination={false}
                              showPaginationBottom={false}
                              showPageSizeOptions={false}
                              className="-highlight mb-3"
                            />
                            {this.props.documentos?.length > 5 &&
                              !this.state.show.documentos && (
                                <div className="w-100">
                                  <ButtonPrimary
                                    title="Ver mais documentos"
                                    onClick={() => {
                                      let show = this.state.show;
                                      show.documentos = true;
                                      this.setState({
                                        show: show,
                                      });
                                    }}
                                  >
                                    VER + DOCUMENTOS
                                  </ButtonPrimary>
                                  {invalidos_len - 5 > 0 && (
                                    <span
                                      className="ml-2 font-weight-500"
                                      style={{
                                        color: COLOR_WARNING,
                                        fontSize: "14px",
                                      }}
                                    >
                                      <FontAwesomeIcon icon="exclamation-triangle" />{" "}
                                      + {invalidos_len - 5} irregulares
                                    </span>
                                  )}
                                </div>
                              )}
                            {this.props.documentos?.length > 5 &&
                              this.state.show.documentos && (
                                <ButtonPrimary
                                  title="Ver menos documentos"
                                  onClick={() => {
                                    let show = this.state.show;
                                    show.documentos = false;
                                    this.setState({
                                      show: show,
                                    });
                                  }}
                                >
                                  VER - DOCUMENTOS
                                </ButtonPrimary>
                              )}

                            <h3
                              className="font-weight-400 mt-5 mb-3 py-2"
                              style={{
                                color: COLOR_DARK2,
                                borderBottom: "2px solid #c8c8c8",
                              }}
                            >
                              {contratoFilter
                                ? "Funcionários do Contrato"
                                : "Meus Funcionários"}
                              {contratoFilter &&
                                contratoFilter.tipo === "terceiro" && (
                                  <span
                                    onClick={() => {
                                      this.props.dispatch(
                                        setModalId(contratoFilter.idContrato)
                                      );
                                      this.props.dispatch(
                                        setModalOpen("funcionarios")
                                      );
                                    }}
                                    className="small float-right mt-1"
                                  >
                                    <FontAwesomeIcon icon="pencil-alt" />
                                  </span>
                                )}
                            </h3>
                            <div className="input-group mb-4">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text"
                                  style={{
                                    height: "38px",
                                    backgroundColor: COLOR_PRIMARY,
                                    borderColor: COLOR_PRIMARY,
                                    color: "white",
                                  }}
                                >
                                  <FontAwesomeIcon icon="search" />
                                </span>
                              </div>
                              <input
                                tabIndex={-1}
                                id="pesquisa-nome-cargo"
                                className="form-control"
                                placeholder={"Pesquisa por nome ou cargo"}
                                onChange={this.handlePesquisaFuncionario}
                              ></input>
                            </div>
                            {this.props.funcionarios?.length > 0 &&
                              this.props.funcionarios
                                ?.sort(
                                  (a, b) =>
                                    a.statusContratoFuncionario -
                                    b.statusContratoFuncionario
                                )
                                .filter(this.handlePesquisaFuncionarioFilter)
                                .map((funcionario) => (
                                  <DocumentosFuncionarioTable
                                    dispatch={this.props.dispatch}
                                    key={funcionario.idFilialFuncionario}
                                    delayed
                                    contratoFilter={contratoFilter}
                                    filialFilter={filialFilter}
                                    funcionario={funcionario}
                                    handleAprovaReprovaTrocaContrato={
                                      this.handleAprovaReprovaTrocaContrato
                                    }
                                    handleEmployeeDismissal={
                                      this.handleEmployeeDismissal
                                    }
                                    handleLoadContratos={
                                      this.handleLoadContratos
                                    }
                                    handleOpen={openDocumentEmployee}
                                    handleOpenJustificativa={
                                      this.handleOpenJustificativa
                                    }
                                    scroll={this.props.scroll}
                                    show={this.state.show}
                                    setShow={this.setShow}
                                    expired={vencido}
                                    onAprovarDocumentosClick={
                                      this.handleAprovarDocumentosClick
                                    }
                                    onDocumentClickUpload={(
                                      props,
                                      funcionario
                                    ) =>
                                      this.handleOpenUpload(
                                        "FUN",
                                        props.original,
                                        funcionario,
                                        contratoFilter
                                      )
                                    }
                                  />
                                ))}
                          </>
                        )}
                        {this.props.funcionarios?.length === 0 &&
                          contratoFilter &&
                          contratoFilter.tipo === "terceiro" && (
                            <ButtonSuccess
                              className="d-block mx-auto"
                              onClick={() => {
                                this.props.dispatch(
                                  setModalId(contratoFilter.idContrato)
                                );
                                this.props.dispatch(
                                  setModalOpen("funcionarios")
                                );
                              }}
                            >
                              <FontAwesomeIcon icon="user-plus" /> Adicionar
                              funcionários deste contrato
                            </ButtonSuccess>
                          )}
                        {this.props.funcionarios?.length === 0 &&
                          contratoFilter &&
                          contratoFilter.tipo !== "terceiro" && (
                            <p>Nenhum funcionário encontrado.</p>
                          )}
                        {this.props.funcionarios?.length > 0 &&
                          this.props.funcionarios.filter(
                            this.handlePesquisaFuncionarioFilter
                          ).length === 0 && (
                            <p>Nenhum funcionário encontrado.</p>
                          )}
                        {(() => {
                          const funcionariosDemitidos =
                            this.props.funcionarios?.filter(
                              (func) =>
                                func.statusContratoFuncionario === DEMITIDO
                            );

                          return (
                            contratoFilter &&
                            funcionariosDemitidos?.length > 0 && (
                              <Accordion defaultActiveKey="1">
                                <h3
                                  className="font-weight-400 mt-5 mb-3 py-2"
                                  style={{
                                    borderBottom: "2px solid #c8c8c8",
                                  }}
                                >
                                  <Accordion.Toggle
                                    className="cursor-pointer"
                                    onClick={() => this.handleFiredCollapse()}
                                    eventKey="1"
                                    style={{
                                      color: COLOR_GRAY2,
                                    }}
                                  >
                                    {this.state.isFiredCollapseOpen ? (
                                      <FontAwesomeIcon icon="caret-down" />
                                    ) : (
                                      <FontAwesomeIcon icon="caret-up" />
                                    )}{" "}
                                    Funcionários Demitidos
                                  </Accordion.Toggle>
                                </h3>
                                <Accordion.Collapse eventKey="1">
                                  <>
                                    {funcionariosDemitidos
                                      ?.sort(
                                        (a, b) =>
                                          a.statusContratoFuncionario -
                                          b.statusContratoFuncionario
                                      )
                                      .map((funcionario) => (
                                        <DocumentosFuncionarioTable
                                          dispatch={this.props.dispatch}
                                          key={funcionario.idFilialFuncionario}
                                          delayed
                                          contratoFilter={contratoFilter}
                                          filialFilter={filialFilter}
                                          funcionario={funcionario}
                                          handleAprovaReprovaTrocaContrato={
                                            this
                                              .handleAprovaReprovaTrocaContrato
                                          }
                                          handleEmployeeDismissal={
                                            this.handleEmployeeDismissal
                                          }
                                          handleLoadContratos={
                                            this.handleLoadContratos
                                          }
                                          handleOpen={openDocumentEmployee}
                                          handleOpenJustificativa={
                                            this.handleOpenJustificativa
                                          }
                                          scroll={this.props.scroll}
                                          show={this.state.show}
                                          setShow={this.setShow}
                                          expired={vencido}
                                          onAprovarDocumentosClick={
                                            this.handleAprovarDocumentosClick
                                          }
                                          onDocumentClickUpload={(
                                            props,
                                            funcionario
                                          ) =>
                                            this.handleOpenUpload(
                                              "FUN",
                                              props.original,
                                              funcionario,
                                              contratoFilter
                                            )
                                          }
                                        />
                                      ))}
                                  </>
                                </Accordion.Collapse>
                              </Accordion>
                            )
                          );
                        })()}
                        {contratoFilter &&
                          this.props.funcionariosRemovidos?.length > 0 && (
                            <Accordion>
                              <h3
                                className="font-weight-400 mt-5 mb-3 py-2"
                                style={{
                                  borderBottom: "2px solid #c8c8c8",
                                }}
                              >
                                <Accordion.Toggle
                                  className="cursor-pointer"
                                  onClick={() => this.handleCollapse()}
                                  eventKey="1"
                                  style={{
                                    color: COLOR_GRAY2,
                                  }}
                                >
                                  {this.state.isCollapseOpen ? (
                                    <FontAwesomeIcon icon="caret-down" />
                                  ) : (
                                    <FontAwesomeIcon icon="caret-up" />
                                  )}{" "}
                                  Funcionários Removidos do Contrato
                                </Accordion.Toggle>
                              </h3>
                              <Accordion.Collapse eventKey="1">
                                <>
                                  {this.props.funcionariosRemovidos?.map(
                                    (funcionario) => (
                                      <DocumentosFuncionarioTable
                                        dispatch={this.props.dispatch}
                                        key={funcionario.idFilialFuncionario}
                                        delayed
                                        contratoFilter={contratoFilter}
                                        filialFilter={filialFilter}
                                        funcionario={funcionario}
                                        handleAprovaReprovaTrocaContrato={
                                          this.handleAprovaReprovaTrocaContrato
                                        }
                                        handleEmployeeDismissal={
                                          this.handleEmployeeDismissal
                                        }
                                        handleLoadContratos={
                                          this.handleLoadContratos
                                        }
                                        handleOpen={openDocumentEmployee}
                                        handleOpenJustificativa={
                                          this.handleOpenJustificativa
                                        }
                                        onlyView
                                        scroll={this.props.scroll}
                                        show={this.state.show}
                                        setShow={this.setShow}
                                        onDocumentClickUpload={(
                                          props,
                                          funcionario
                                        ) =>
                                          this.handleOpenUpload(
                                            "FUN",
                                            props.original,
                                            funcionario,
                                            contratoFilter
                                          )
                                        }
                                      />
                                    )
                                  )}
                                </>
                              </Accordion.Collapse>
                            </Accordion>
                          )}
                      </>
                    )}
                  </MaxViewHeightContainer>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Formulário de Criação de Contrato */}
        <RightPanel
          isPaneOpen={this.props.modal === "new"}
          title="Novo contrato"
          path="new"
          onClose={() => {
            this.props.dispatch(setModalOpen(""));
          }}
        >
          <ContratoForm
            filial={filialFilter}
            empresa={empresaFilter}
            onAfterSave={this.handleFetch}
          ></ContratoForm>
        </RightPanel>

        <RightPanel
          isPaneOpen={this.props.modal === "edit" && this.props.id !== null}
          title="Edição de Contrato"
          path="edit"
          onClose={() => {
            this.props.dispatch(setModalOpen(""));
          }}
        >
          <ContratoForm
            onAfterDelete={() => {
              this.handleClearFilter();
            }}
            onAfterSave={this.handleFetch}
            id={this.props.id}
          ></ContratoForm>
        </RightPanel>

        {/* Edição de Documentos do Contrato */}
        <RightPanel
          isPaneOpen={this.props.modal === "documentos"}
          title="Documentos do Contrato"
          path="documentos"
          onClose={() => {
            this.handleFetch();
            this.props.dispatch(setModalOpen(""));
          }}
        >
          <ContratoDocumentos
            onAfterSave={this.handleFetch}
          ></ContratoDocumentos>
        </RightPanel>

        {/* Edição de Funcionários do Contrato */}
        <RightPanel
          isPaneOpen={this.props.modal === "funcionarios"}
          title="Funcionários do Contrato"
          path="funcionarios"
          onClose={() => {
            this.handleFetch();
            this.props.dispatch(setModalOpen(""));
          }}
        >
          <ContratoFuncionarios
            onAfterSave={this.handleFetch}
          ></ContratoFuncionarios>
        </RightPanel>
        <RightPanel
          isPaneOpen={this.props.modal === "comments"}
          title="Comentários"
          path="comments"
          onClose={() => {
            this.props.dispatch(setModalOpen(""));
            this.props.dispatch(clearComentario());
          }}
        >
          <ComentarioForm
            tipoContrato={this.state.tipoContrato}
            contrato={this.state.contrato}
            onAfterSave={this.requestData}
          ></ComentarioForm>
        </RightPanel>

        <RightPanel
          isPaneOpen={this.props.modal === "upload"}
          title="Upload de Arquivo"
          path="upload"
          onClose={() => this.props.dispatch(setModalOpen(""))}
        >
          <Upload
            contrato={contratoFilter?.idContrato}
            onAfterSave={() => {
              this.props.dispatch(setAppData({ documento: null }));

              const reqData = {
                filial: this.getFilialFilter().idFilial,
                empresa: this.getEmpresaFilter()?.idFilial || null,
                contrato: this.getContratoFilter()?.idContrato || null,
              };

              if (this.state.tipoDocumentoUpload === "CON") {
                this.props.dispatch(fetchContratoList(reqData));
                return;
              }

              if (this.state.tipoDocumentoUpload === "EMP") {
                this.props.dispatch(fetchDocumentosList(reqData));
                return;
              }

              const idFilialFuncionario =
                this.props.filialFuncionario?.idFilialFuncionario;
              this.props.dispatch(
                fetchDocumentosFuncionario({
                  ...reqData,
                  filial_funcionario_id: idFilialFuncionario,
                })
              );
              this.props.dispatch(
                fetchDocumentosFuncionarioRemovido({
                  ...reqData,
                  filial_funcionario_id: idFilialFuncionario,
                })
              );
            }}
          />
        </RightPanel>
        <RightPanel
          isPaneOpen={this.props.modal === "portability"}
          title="Portabilidade de Documentos"
          path="portability"
          onClose={() => this.props.dispatch(setModalOpen(""))}
        >
          <DocumentsPortability
            isVisible={this.props.modal === "portability"}
            afterSave={this.handleFetch}
          />
        </RightPanel>
        <RightPanel
          isPaneOpen={this.props.modal === "approveDocuments"}
          title="Aprovar Documentos"
          path="approve"
          onClose={() => this.props.dispatch(setModalOpen(""))}
        >
          <ApproveDocuments
            isVisible={this.props.modal === "approveDocuments"}
            afterSave={this.afterApproveDocuments}
          />
        </RightPanel>
      </div>
    );
  }
}

GestaoDocumentos.propTypes = {
  modal: PropTypes.string.isRequired,
  id: PropTypes.any,
  permissoes: PropTypes.object.isRequired,
  usuarioLogado: PropTypes.object.isRequired,
  dados: PropTypes.array.isRequired,
  documentos: PropTypes.array.isRequired,
  funcionarios: PropTypes.array.isRequired,
  documentosContrato: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  loadingEmpresas: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  permissoes: state.permissions.permissoes,
  usuarioLogado: state.usuarioLogado.dados,
  appData: state.dados.data,

  // Modal
  modal: state.modal.modalOpen,
  id: state.modal.id,
  extraData: state.modal.extraData,

  // Gestão de Documentos
  dados: state.gestaoDocumentos.gestaoDocumentos || [],
  loadingEmpresas: !!state.gestaoDocumentos.loading,
  error: state.gestaoDocumentos.error,

  // Documentos
  documentos: state.documentos.documentos,
  loading: state.documentos.loading,

  // Documentos dos funcionários
  funcionarios: state.documentosFuncionarios.funcionarios,
  funcionariosRemovidos:
    state.documentosFuncionariosRemovidos.funcionariosRemovidos,

  // Documentos do contrato
  documentosContrato: state.documentosContrato.contrato,
  loadingDocumentosContrato: state.documentosContrato.loading,

  // Scroll
  scroll: state.scroll,

  // Filial Funcionario
  filialFuncionario: state.filialFuncionario,

  // refresh
  refresh: state.refresh,
});

export default connect(mapStateToProps)(withRouter(GestaoDocumentos));
