import React, { memo, useCallback } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  COLOR_DANGER,
  COLOR_WARNING,
  COLOR_INFO,
  COLOR_GREEN,
} from "../../../Colors";
import { SITUACAO } from "../../../types/SituacaoDocumento";
import { isAuditoria } from "../../../helpers/isAuditoria";

const SituacaoCell = ({
  funcionario,
  handleOpen,
  handleOpenJustificativa,
  showContractsButton = false,
  onShowContracts,
  props,
}) => {
  const ContractsButton = useCallback(
    () => (
      <div
        role="button"
        title="Mostrar Contratos vinculados ao Documento"
        className="d-inline cursor-pointer"
        onClick={() => onShowContracts?.(props, funcionario)}
      >
        <FontAwesomeIcon icon="question-circle" />
      </div>
    ),
    [onShowContracts, props, funcionario]
  );

  if (isAuditoria(funcionario, props.value))
    return (
      <div
        onDoubleClick={() => handleOpen(props.original, funcionario)}
        style={{ color: COLOR_INFO }}
      >
        <FontAwesomeIcon icon="exclamation-circle" /> Auditoria
      </div>
    );

  switch (props.value) {
    case SITUACAO.RECUSADO:
      return (
        <div
          className={props.original?.justificativa ? "cursor-pointer" : ""}
          onDoubleClick={() => handleOpen(props.original, funcionario)}
          style={{
            color: COLOR_DANGER,
          }}
          onClick={
            !props.original?.justificativa
              ? null
              : () => handleOpenJustificativa(props, funcionario)
          }
        >
          <FontAwesomeIcon icon="ban" /> Recusado{" "}
          {props.original?.justificativa && (
            <FontAwesomeIcon icon="question-circle" />
          )}
        </div>
      );
    case SITUACAO.EXPIRADO:
      return (
        <div
          onDoubleClick={() => handleOpen(props.original, funcionario)}
          style={{
            color: COLOR_DANGER,
          }}
        >
          <FontAwesomeIcon icon="exclamation-circle" />
          Expirado
        </div>
      );
    case SITUACAO.UPLOAD_PENDENTE:
      return (
        <div
          onDoubleClick={() => handleOpen(props.original, funcionario)}
          style={{
            color: COLOR_WARNING,
          }}
        >
          <FontAwesomeIcon icon="cloud-upload-alt" /> Pendente
        </div>
      );
    case SITUACAO.EXPIRANDO:
      return (
        <div
          onDoubleClick={() => handleOpen(props.original, funcionario)}
          style={{
            color: COLOR_INFO,
          }}
        >
          <FontAwesomeIcon icon="clock" /> Expirando{" "}
          {showContractsButton && <ContractsButton />}
        </div>
      );
    case SITUACAO.AGUARDANDO_APROVACAO:
      return (
        <div
          onDoubleClick={() => handleOpen(props.original, funcionario)}
          className={props.original?.justificativa ? "cursor-pointer" : ""}
          style={{
            color: COLOR_INFO,
          }}
          onClick={
            !props.original?.justificativa
              ? null
              : () => handleOpenJustificativa(props, funcionario)
          }
        >
          <FontAwesomeIcon icon="hourglass-half" /> Ag. Aprovação{" "}
          {props.original?.justificativa && (
            <FontAwesomeIcon icon="question-circle" />
          )}
          {showContractsButton && <ContractsButton />}
        </div>
      );
    case SITUACAO.SEM_PENDENCIAS:
      return (
        <div
          onDoubleClick={() => handleOpen(props.original, funcionario)}
          style={{
            color: COLOR_GREEN,
          }}
        >
          <FontAwesomeIcon icon="check" /> Regular
        </div>
      );
    default:
      return null;
  }
};

export default memo(SituacaoCell);
