/* Geral / Bibliotecas */
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Calendar, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt"; // the locale you want
import "react-datepicker/dist/react-datepicker.css";
import MaskedTextInput from "react-text-mask";
import moment from "moment";

/* Cores */
import { COLOR_DARK2, COLOR_PRIMARY } from "../../Colors";

/* Métodos / Ações */
import { uploadDocumento } from "../../actions/documentos";
import { clearErrors } from "../../actions/errors";

/* Componentes */
import Error from "../Error";
import LoadingImage from "../LoadingImage";

/* Botões */
import ButtonSuccess from "../Buttons/ButtonSuccess";

/* Campos */
import PdfPreview from "../CustomFields/PdfPreview";

/* Validação */
import { dateField } from "../../validation/formValidation";
import { setModalOpen } from "../../actions/modal";

const MySwal = withReactContent(Swal);

registerLocale("pt", pt);

class Upload extends Component {
    constructor() {
        super();

        this._isMounted = false;
        this.state = {
            loading: true,
        };
    }

    handleSubmit(values, actions) {
        this.props.dispatch(
            uploadDocumento(
                values,
                this.props.dados,
                actions,
                this.props.avaliacao,
                this.props.contrato,
                () => {
                    this.props.onAfterSave && this.props.onAfterSave();
                    setTimeout(() => this.onBack(), 1000);
                }
            )
        );
    }

    onBack = () => {
        if (this.props.modal <= 1) {
            window.history.back();
            return;
        }

        const cont = this.props.modal * 2;
        for (var i = 1; i <= cont - 1; i++) {
            window.history.back();
        }
    };

    componentDidMount() {
        this._isMounted = true;
        this.setState({
            loading: false,
        });

        if (!this.props.dados?.documento?.nome) {
            this.props.dispatch(setModalOpen(""));
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            let show = false;
            if (nextProps.errors.message) {
                show = true;
            }
            this.setState({
                errors: nextProps.errors,
            });
            if (show) {
                MySwal.fire({
                    type: "error",
                    title: nextProps.errors.message,
                    toast: true,
                    position: "center",
                    showConfirmButton: false,
                    timer: 3000,
                });
                this.props.dispatch(clearErrors());
            }
        }
    }

    render() {
        if (this.props.error) {
            return <Error erro={this.props.error}></Error>;
        }

        if (this.state.loading || !this.props.dados?.documento?.nome) {
            return <LoadingImage />;
        }

        return (
            <div className="pb-5">
                <div className="row mx-0 d-flex align-items-center justify-content-center">
                    <div className="col-12 col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <h5
                                    className="font-weight-400 mb-3 pr-5"
                                    style={{ color: COLOR_DARK2 }}
                                >
                                    {this.props.dados.documento.nome}
                                </h5>
                                {this.props.dados.documento.descricao && (
                                    <button
                                        style={{
                                            backgroundColor: "#ff6700",
                                            color: "#ffffff",
                                            fontSize: "2.5em",
                                            borderRadius: "50%",
                                            display: "flex",
                                            padding: "1px",
                                            position: "absolute",
                                            right: 0,
                                            top: "15px",
                                        }}
                                        onClick={() => {
                                            MySwal.fire({
                                                title: this.props.dados
                                                    .documento.nome,
                                                html: this.props.dados.documento
                                                    .descricao,
                                                showConfirmButton: true,
                                                confirmButtonText: "Entendi",
                                                width: "90%",
                                            });
                                            return;
                                        }}
                                    >
                                        <FontAwesomeIcon icon="question-circle" />
                                    </button>
                                )}
                                <Formik
                                    initialValues={{
                                        dataEmissao: new Date(),
                                        arquivo: "",
                                        dataVencimento: "",
                                    }}
                                    onSubmit={this.handleSubmit.bind(this)}
                                    render={({
                                        isSubmitting,
                                        setFieldValue,
                                        values,
                                        handleSubmit,
                                        setFieldError,
                                    }) => (
                                        <Form onSubmit={handleSubmit}>
                                            <ButtonSuccess
                                                style={{
                                                    position: "fixed",
                                                    right: 0,
                                                    top: 0,
                                                    borderRadius: 0,
                                                    height: "64px",
                                                }}
                                                type="submit"
                                                disabled={isSubmitting}
                                            >
                                                <FontAwesomeIcon icon="file-export" />
                                                <span className="d-none d-md-inline-block">
                                                    {" "}
                                                    Enviar
                                                </span>
                                            </ButtonSuccess>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label
                                                            className="w-100"
                                                            htmlFor="dataEmissao"
                                                        >
                                                            Data de Emissão do
                                                            Documento
                                                        </label>
                                                        <Field
                                                            name="dataEmissao"
                                                            validate={dateField}
                                                        >
                                                            {({ field }) => {
                                                                return (
                                                                    <Calendar
                                                                        name="dataEmissao"
                                                                        id="dataEmissao"
                                                                        className="form-control"
                                                                        showLeadingZeros={
                                                                            true
                                                                        }
                                                                        selected={
                                                                            values.dataEmissao
                                                                        }
                                                                        locale={
                                                                            pt
                                                                        }
                                                                        disabled={
                                                                            this
                                                                                .state
                                                                                .lockedFields
                                                                        }
                                                                        placeholderText="-- / -- / ----"
                                                                        dateFormat="dd/MM/yyyy"
                                                                        onChange={(
                                                                            date
                                                                        ) => {
                                                                            setFieldValue(
                                                                                "dataEmissao",
                                                                                date
                                                                            );
                                                                        }}
                                                                        customInput={
                                                                            <MaskedTextInput
                                                                                mask={[
                                                                                    /\d/,
                                                                                    /\d/,
                                                                                    "/",
                                                                                    /\d/,
                                                                                    /\d/,
                                                                                    "/",
                                                                                    /\d/,
                                                                                    /\d/,
                                                                                    /\d/,
                                                                                    /\d/,
                                                                                ]}
                                                                            />
                                                                        }
                                                                    />
                                                                );
                                                            }}
                                                        </Field>
                                                        <ErrorMessage
                                                            name="dataEmissao"
                                                            component="small"
                                                            className="form-text text-danger"
                                                        />
                                                    </div>
                                                </div>
                                                {this.props.dados.documento
                                                    .validade === 0 && (
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label
                                                                className="w-100"
                                                                htmlFor="dataVencimento"
                                                            >
                                                                Data de
                                                                Vencimento do
                                                                Documento
                                                            </label>
                                                            <Field
                                                                name="dataVencimento"
                                                                validate={
                                                                    dateField
                                                                }
                                                            >
                                                                {({
                                                                    field,
                                                                }) => {
                                                                    return (
                                                                        <Calendar
                                                                            name="dataVencimento"
                                                                            id="dataVencimento"
                                                                            className="form-control"
                                                                            showLeadingZeros={
                                                                                true
                                                                            }
                                                                            selected={
                                                                                values.dataVencimento
                                                                            }
                                                                            locale={
                                                                                pt
                                                                            }
                                                                            disabled={
                                                                                this
                                                                                    .state
                                                                                    .lockedFields
                                                                            }
                                                                            placeholderText="-- / -- / ----"
                                                                            dateFormat="dd/MM/yyyy"
                                                                            onChange={(
                                                                                date
                                                                            ) => {
                                                                                setFieldValue(
                                                                                    "dataVencimento",
                                                                                    date
                                                                                );
                                                                            }}
                                                                            customInput={
                                                                                <MaskedTextInput
                                                                                    mask={[
                                                                                        /\d/,
                                                                                        /\d/,
                                                                                        "/",
                                                                                        /\d/,
                                                                                        /\d/,
                                                                                        "/",
                                                                                        /\d/,
                                                                                        /\d/,
                                                                                        /\d/,
                                                                                        /\d/,
                                                                                    ]}
                                                                                />
                                                                            }
                                                                        />
                                                                    );
                                                                }}
                                                            </Field>
                                                            <ErrorMessage
                                                                name="dataVencimento"
                                                                component="small"
                                                                className="form-text text-danger"
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            {!this.props.avaliacao &&
                                                !this.props.contrato &&
                                                this.props.dados.envio && (
                                                    <div className="row">
                                                        <div
                                                            className="col-12 text-center py-3 cursor-pointer"
                                                            style={{
                                                                color: COLOR_PRIMARY,
                                                                fontSize:
                                                                    "18px",
                                                            }}
                                                            onClick={() => {
                                                                if (
                                                                    this.props
                                                                        .dados
                                                                        .envio
                                                                        .vai_para
                                                                        ?.length ===
                                                                    0
                                                                ) {
                                                                    MySwal.fire(
                                                                        {
                                                                            title: "Contratos",
                                                                            html: "Nenhum contrato",
                                                                            showConfirmButton: true,
                                                                            confirmButtonText:
                                                                                "OK",
                                                                        }
                                                                    );
                                                                } else {
                                                                    let html =
                                                                        '<div class="row">';
                                                                    for (
                                                                        let i = 0;
                                                                        i <
                                                                        this
                                                                            .props
                                                                            .dados
                                                                            .envio
                                                                            .vai_para
                                                                            ?.length;
                                                                        i++
                                                                    ) {
                                                                        let contr =
                                                                            this
                                                                                .props
                                                                                .dados
                                                                                .envio
                                                                                .vai_para[
                                                                                i
                                                                            ];
                                                                        html += `<div class="col-12 pb-2">
                                                                            &bull;<b>${contr.titulo.toUpperCase()}</b> <span class="small">com</span> ${contr.tomador.toUpperCase()}
                                                                        </div>`;
                                                                    }
                                                                    html +=
                                                                        "</div>";
                                                                    MySwal.fire(
                                                                        {
                                                                            title: "Contratos",
                                                                            html: html,
                                                                            width: "90%",
                                                                            showConfirmButton: true,
                                                                            confirmButtonText:
                                                                                "OK",
                                                                        }
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <FontAwesomeIcon icon="exclamation-triangle" />{" "}
                                                            Este arquivo será
                                                            enviado para{" "}
                                                            <b>
                                                                {
                                                                    this.props
                                                                        .dados
                                                                        .envio
                                                                        .vai_para
                                                                        ?.length
                                                                }
                                                            </b>{" "}
                                                            contrato(s)!
                                                        </div>
                                                    </div>
                                                )}
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span
                                                                    className="input-group-text"
                                                                    id="inputGroupFileAddon01"
                                                                >
                                                                    Arquivo
                                                                    (PDF) *
                                                                </span>
                                                            </div>
                                                            <div className="custom-file">
                                                                <input
                                                                    type="file"
                                                                    className="custom-file-input"
                                                                    id="arquivo"
                                                                    name="arquivo"
                                                                    accept="application/pdf"
                                                                    aria-describedby="inputGroupFileAddon01"
                                                                    onChange={(
                                                                        event
                                                                    ) => {
                                                                        if (
                                                                            !event
                                                                                .target
                                                                                .files[0] ||
                                                                            (event
                                                                                .target
                                                                                .files[0]
                                                                                .type !==
                                                                                "application/pdf" &&
                                                                                event
                                                                                    .target
                                                                                    .files[0]
                                                                                    .type !==
                                                                                    "application/wps-office.pdf")
                                                                        ) {
                                                                            MySwal.fire(
                                                                                {
                                                                                    type: "error",
                                                                                    title: "Selecione um arquivo PDF",
                                                                                    toast: true,
                                                                                    position:
                                                                                        "center",
                                                                                    showConfirmButton: false,
                                                                                    timer: 2000,
                                                                                }
                                                                            );
                                                                            return;
                                                                        }
                                                                        if (
                                                                            event
                                                                                .target
                                                                                .files[0] &&
                                                                            event
                                                                                .target
                                                                                .files[0]
                                                                                .lastModified
                                                                        ) {
                                                                            let date =
                                                                                event
                                                                                    .target
                                                                                    .files[0]
                                                                                    .lastModified;
                                                                            MySwal.fire(
                                                                                {
                                                                                    title: "",
                                                                                    type: "question",
                                                                                    html:
                                                                                        "Deseja usar <b>" +
                                                                                        moment(
                                                                                            date
                                                                                        ).format(
                                                                                            "DD/MM/YYYY"
                                                                                        ) +
                                                                                        "</b> como data de emissão?",
                                                                                    showCancelButton: true,
                                                                                    confirmButtonText:
                                                                                        "Sim",
                                                                                    cancelButtonText:
                                                                                        "Não",
                                                                                    reverseButtons: true,
                                                                                }
                                                                            ).then(
                                                                                (
                                                                                    result
                                                                                ) => {
                                                                                    if (
                                                                                        !!result.dismiss
                                                                                    ) {
                                                                                        setFieldValue(
                                                                                            "dataEmissao",
                                                                                            ""
                                                                                        );
                                                                                    }

                                                                                    if (
                                                                                        result.value
                                                                                    ) {
                                                                                        setFieldValue(
                                                                                            "dataEmissao",
                                                                                            new Date(
                                                                                                date
                                                                                            )
                                                                                        );
                                                                                    }
                                                                                }
                                                                            );
                                                                        }
                                                                        setFieldValue(
                                                                            "arquivo",
                                                                            event
                                                                                .target
                                                                                .files[0]
                                                                        );
                                                                    }}
                                                                />
                                                                <label
                                                                    className="custom-file-label"
                                                                    htmlFor="arquivo"
                                                                >
                                                                    Escolher
                                                                    arquivo
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <ErrorMessage
                                                            name="arquivo"
                                                            component="small"
                                                            className="form-text text-danger"
                                                        />
                                                        <div className="row d-flex align-items-center justify-content-center">
                                                            <div className="col-12">
                                                                <PdfPreview
                                                                    file={
                                                                        values.arquivo
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 d-flex align-items-center justify-content-center pt-4">
                                                    <ButtonSuccess
                                                        type="submit"
                                                        disabled={isSubmitting}
                                                    >
                                                        <FontAwesomeIcon icon="file-export" />{" "}
                                                        Enviar
                                                    </ButtonSuccess>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Upload.propTypes = {
    errors: PropTypes.object.isRequired,
    dados: PropTypes.any,
    onAfterSave: PropTypes.func,
};

const mapStateToProps = (state) => ({
    dados: state.dados.data,
    errors: state.errors,
});

export default connect(mapStateToProps)(withRouter(Upload));
