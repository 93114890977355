import moment from "moment";

export const isExpired = (contrato) => {
  if (contrato.dataFim instanceof Date) {
    return moment(contrato.dataFim).isBefore(moment());
  }

  return (
    contrato.dataFim?.date && moment(contrato.dataFim.date).isBefore(moment())
  );
};
